'use client';

export default function AddIconCustom({
  width = '18',
  height = '18',
  stroke = '#002A3A',
  strokeWidth = '1.5',
  paddingLeft = '8px',
}) {
  // TODO: make more dynamic with move to design system 4.0
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ paddingLeft: { paddingLeft } }}
    >
      <path
        d='M1.5 9H16.5'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 1.5V16.5'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
