import './TextTestimonial.scss';

export default function TextTestimonial({ content = {} }) {
  const { name = '', text: { html: textHtml } = '', additionalText: { html: addTextHtml } = '' } = content;

  return (
    <>
      <blockquote dangerouslySetInnerHTML={{ __html: textHtml }} className='mb-4' />
      <div className='mb-2'>{name}</div>
      <div className='opacity-50' dangerouslySetInnerHTML={{ __html: addTextHtml }} />
    </>
  );
}
