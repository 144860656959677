'use client';

export default function ChevronRight({ height = '20', width = '12', color = '#002A3A' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.00827 0.183038C0.791306 0.399985 0.767184 0.736765 0.935918 0.980358L1.00823 1.06692L9.9402 10L1.00823 18.9331C0.791285 19.15 0.767194 19.4868 0.935951 19.7304L1.00827 19.817C1.22524 20.0339 1.56202 20.058 1.8056 19.8892L1.89216 19.8169L10.8239 10.8844C11.2751 10.4346 11.3103 9.72508 10.9294 9.23492L10.8251 9.11681L1.89216 0.183079C1.64809 -0.0610102 1.25236 -0.0610287 1.00827 0.183038Z'
        fill={color}
      />
    </svg>
  );
}
