'use client';

export default function ChevronLeft({
  width = '17',
  height = '18',
  color = '#002A3A',
  style,
  strokeWidth = '1.5',
}) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox='0 0 17 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.4768 16.5L5.3301 9.35333C5.28365 9.30696 5.24681 9.25188 5.22167 9.19125C5.19653 9.13062 5.18359 9.06563 5.18359 9C5.18359 8.93437 5.19653 8.86938 5.22167 8.80875C5.24681 8.74812 5.28365 8.69304 5.3301 8.64667L12.4768 1.5'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
