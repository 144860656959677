'use client';

export default function Map({ color = '#002A3A', width = '32', height = '32' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.307 3.03798C15.9494 2.89497 15.5506 2.89497 15.193 3.03798L8.807 5.59298C8.44944 5.73598 8.05057 5.73598 7.693 5.59298L1.779 3.22698C1.54728 3.13411 1.28455 3.16262 1.07814 3.30303C0.871741 3.44344 0.748728 3.67734 0.750002 3.92698V18.3C0.7499 18.9135 1.12338 19.4652 1.693 19.693L7.693 22.093C8.05057 22.236 8.44944 22.236 8.807 22.093L15.193 19.538C15.5506 19.395 15.9494 19.395 16.307 19.538L22.221 21.9C22.4527 21.9928 22.7155 21.9643 22.9219 21.8239C23.1283 21.6835 23.2513 21.4496 23.25 21.2V6.83098C23.2501 6.21749 22.8766 5.66578 22.307 5.43798L16.307 3.03798Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 5.69995V22.2'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.75 2.93097V19.431'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
