'use client';

export default function GraphStats({ color = '#28393D', height = '42', width = '42' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M38.3333 1H3.66667C2.19391 1 1 2.19391 1 3.66667V38.3333C1 39.8061 2.19391 41 3.66667 41H38.3333C39.8061 41 41 39.8061 41 38.3333V3.66667C41 2.19391 39.8061 1 38.3333 1Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.33325 22.3329H11.6666L15.6844 12.2884C15.7855 12.038 15.9604 11.8243 16.1858 11.6756C16.4113 11.5269 16.6766 11.4503 16.9466 11.4559C17.2166 11.4614 17.4785 11.549 17.6976 11.7069C17.9167 11.8648 18.0826 12.0856 18.1733 12.34L24.0257 28.9178C24.1107 29.1585 24.263 29.3697 24.4646 29.5264C24.6661 29.6831 24.9084 29.7786 25.1627 29.8017C25.4169 29.8247 25.6724 29.7742 25.8988 29.6563C26.1252 29.5384 26.313 29.358 26.4399 29.1364L30.3333 22.3329H35.6666'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
