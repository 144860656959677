'use client';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from 'next/link';
import { replaceUrls } from 'utils/urls';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { ButtonOnDark } from '../../buttons';
import LingoHeaderLogo from '../../icons/lingoHeaderLogo';
import { QuestionCircle } from '../../icons';

export default function SideNavBar({ menuData, cta, environment }) {
  const [lingoHomeUrl, setLingoHomeUrl] = useState('');
  const supportLinkUrl = 'https://support.hellolingo.com/hc/en-us';

  useEffect(() => {
    const sanitizedLingoHomeUrl = replaceUrls('https://www.hellolingo.com/', environment);
    setLingoHomeUrl(sanitizedLingoHomeUrl);
  }, [environment]);

  const startPosition = 20;
  const maxScrollValue = 150;
  const opacityValue = `calc((((var(--scroll) - ${startPosition}) / ${maxScrollValue}) * 100) / 100)`;

  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <Box
        display={{
          xs: 'grid',
          md: 'none',
        }}
        gridTemplateColumns='repeat(12, 1fr)'
        gap={{
          xs: '0 8px',
          sm: '0 12px',
        }}
        pt='32px'
        pb='20px'
        position='sticky'
        top={0}
        zIndex='3'
        style={{
          backgroundColor: `rgba(241, 238, 230, ${opacityValue})`,
        }}
        data-analytics-location='header'
      >
        <Box gridColumn='2/4'>
          <Link
            href={lingoHomeUrl}
            data-analytics-action='home'
            data-analytics-location='header'
            data-testid='headerLogo'
          >
            <Box
              fontSize='5px'
              width='0px'
              height='0px'
              overflow='hidden'
              color='colorTheme.contentPrimaryOnDark'
            >
              route back to homepage
            </Box>
            <LingoHeaderLogo width='79px' height='32px' alt='logo of lingo spelled out that routes to' />
          </Link>
        </Box>
        {!menuOpen && (
          <Box
            gridColumn='11/11'
            textAlign='right'
            sx={{
              cursor: 'pointer',
            }}
            tabIndex={0}
            aria-label='Open Menu'
            onClick={() => setMenuOpen(true)}
            onKeyDown={() => setMenuOpen(true)}
            data-testid='headerMenuButton'
          >
            <MenuIcon />
          </Box>
        )}
      </Box>
      <Box
        display={{
          sm: 'initial',
          md: 'none',
        }}
        zIndex='150'
        position='fixed'
        top={0}
        height='100%'
        width='100%'
        color='colorTheme.contentPrimaryOnDark'
        sx={{
          transition: 'transform 0.3s ease-in-out',
          transform: menuOpen ? 'translateX(0)' : 'translateX(100%)',
        }}
      >
        <Box data-testid='uncoveredHeaderMenuMobileScreen' height='100%' onClick={() => setMenuOpen(false)} />
        <Box
          data-testid='headerMenuMobile'
          zIndex='150'
          bgcolor='colorTheme.backgroundPrimaryDark'
          position='fixed'
          height='100%'
          width='100%'
          top='0'
          right='0'
          padding={{
            xs: '36px 32px',
            sm: '36px 64px',
          }}
          color='colorTheme.contentPrimaryOnDark'
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
        >
          <Box textAlign='right' flexShrink='1'>
            <Typography
              variant='bodyMBold'
              sx={{ cursor: 'pointer' }}
              tabIndex={0}
              aria-label='Close Menu'
              onClick={() => setMenuOpen(false)}
              onKeyDown={() => setMenuOpen(false)}
            >
              <CloseIcon sx={{ color: '#ffffff' }} />
            </Typography>
          </Box>
          <Stack flexGrow='1' pt={9}>
            {menuData?.sectionLinksAndLabels.items.map((text, index) => {
              const url = replaceUrls(text.link, environment);
              return (
                <Link
                  href={url}
                  onClick={() => setMenuOpen(false)}
                  key={text.title}
                  data-analytics-action={text.title}
                  data-analytics-location='header'
                  style={{ borderTop: '1px solid white', paddingBlock: '13px' }}
                >
                  <Typography
                    variant='h0medium'
                    color='colorTheme.contentPrimaryOnDark'
                    data-testid={`menuLittleText${index}`}
                  >
                    {text.title}
                  </Typography>
                </Link>
              );
            })}
          </Stack>
          <Stack gap='24px' justifyContent='flex-end'>
            <Link
              href={replaceUrls(supportLinkUrl ?? '', environment)}
              onClick={() => setMenuOpen(false)}
              data-analytics-action='zendesk-help'
              data-analytics-location='header'
            >
              <Box display='flex' justifyContent='flex-start' gap='8px' alignItems='center'>
                <QuestionCircle color='#F1EEE6' />
                <Typography variant='h4' color='colorTheme.contentPrimaryOnDark'>
                  Help
                </Typography>
              </Box>
            </Link>
            <ButtonOnDark
              data-analytics-action={cta.text}
              data-analytics-location='header'
              href={replaceUrls(cta.url ?? '', environment)}
            >
              <Typography variant='buttonL'>{cta.text}</Typography>
            </ButtonOnDark>
          </Stack>
        </Box>
      </Box>
    </>
  );
}
