'use client';

export default function MoodIcon({ color = '#0A89FF', width = '48', height = '47' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 48 47' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M42.1965 26.2422C42.1965 36.9583 33.8351 45.5972 23.5792 45.5972C13.3233 45.5972 4.96185 36.9583 4.96185 26.2422C4.96185 15.5261 13.3233 6.88721 23.5792 6.88721C33.8351 6.88721 42.1965 15.5261 42.1965 26.2422Z'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M46.6876 20.6675C46.6876 31.3836 38.3261 40.0225 28.0702 40.0225C17.8143 40.0225 9.45288 31.3836 9.45288 20.6675C9.45288 9.95136 17.8143 1.3125 28.0702 1.3125C38.3261 1.3125 46.6876 9.95136 46.6876 20.6675Z'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M38.5472 20.6675C38.5472 31.3836 30.1857 40.0225 19.9298 40.0225C9.67395 40.0225 1.3125 31.3836 1.3125 20.6675C1.3125 9.95136 9.67395 1.3125 19.9298 1.3125C30.1857 1.3125 38.5472 9.95136 38.5472 20.6675Z'
        stroke={color}
        strokeWidth='1.5'
      />
    </svg>
  );
}
