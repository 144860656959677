'use client';

export default function MobilePhone({ color = '#28393D', width = '100%', height = '100%' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid='mobilePhoneIcon'
    >
      <path
        d='M26.6667 1H13.3333C10.3878 1 8 3.38782 8 6.33333V35.6667C8 38.6123 10.3878 41 13.3333 41H26.6667C29.6123 41 32 38.6123 32 35.6667V6.33333C32 3.38782 29.6123 1 26.6667 1Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8 31.2227H32' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
