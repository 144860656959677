'use client';

export default function LingoWhiteFooter() {
  return (
    <svg width='150' height='63' viewBox='0 0 150 63' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 32.749V0.557359H9.02829V38.2467H28.0565V46.5052H13.8806C6.21442 46.5052 0 40.3465 0 32.749ZM31.627 5.43656C31.627 2.40905 33.9887 0 37.1128 0C40.2368 0 42.6676 2.40905 42.6676 5.43656C42.6676 8.46408 40.2368 10.9416 37.1128 10.9416C33.9887 10.9416 31.627 8.46408 31.627 5.43656V5.43656ZM41.5578 46.5052H32.877V14.7098H41.5578V46.5052V46.5052ZM52.447 14.7098L53.8596 18.6336C55.0404 17.3263 58.0262 14.0228 64.207 14.0228C74.4142 14.0228 77.0543 20.8352 77.0543 28.5438V46.5052H68.3736V28.5438C68.3736 24.4145 66.7069 21.8685 62.8187 21.8685C58.6521 21.8685 56.6379 24.6219 55.8737 26.8236V46.507H47.193V14.7098H52.4452H52.447ZM104.677 46.8496V45.9552C103.218 46.5052 100.163 47.2625 96.9681 47.2625C87.3849 47.2625 81.0659 39.9669 81.0659 30.6769C81.0659 21.387 87.6633 14.0228 96.4132 14.0228C103.218 14.0228 106.899 18.7724 106.899 18.7724L108.354 14.7172H113.356V47.3051C113.356 55.5674 106.598 62.2649 98.2611 62.2649H88.1771V54.7211H104.675V46.8496H104.677ZM104.677 26.2718C103.982 24.483 101.761 21.8666 97.5941 21.8666C92.5941 21.8666 90.025 26.2033 90.025 30.6751C90.025 35.1469 92.7343 39.4151 98.0107 39.4151C102.039 39.4151 104.677 37.6263 104.677 37.6263V26.2699V26.2718ZM133.543 14.021C142.64 14.021 150 21.5907 150 30.5381C150 39.4854 142.638 47.2625 133.543 47.2625C124.447 47.2625 117.155 39.6243 117.155 30.5381C117.155 21.4518 124.516 14.021 133.543 14.021V14.021ZM133.543 39.4169C138.195 39.4169 141.945 35.4247 141.945 30.5381C141.945 25.6515 138.195 21.8666 133.543 21.8666C128.89 21.8666 125.21 25.72 125.21 30.5381C125.21 35.3562 128.89 39.4169 133.543 39.4169V39.4169Z'
        fill='white'
      />
    </svg>
  );
}
