'use client'

export default function arrowLeftLong({ color = '#002A3A', opacity = '1', width = '35', height = '25' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0 12.5H34' stroke={color} strokeOpacity={opacity} />
      <path d='M12.3333 24L0.999919 12.5L12.3333 1' stroke={color} strokeOpacity={opacity} />
    </svg>
  )
}
