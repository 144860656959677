"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.STG = exports.RESEARCH = exports.QA = exports.PROD = exports.LOCAL = exports.DEV = void 0;
// env = 'local' || 'research' || 'dev' || 'qa' || 'stg' || 'prod'

var LOCAL = 'local';
exports.LOCAL = LOCAL;
var DEV = 'dev';
exports.DEV = DEV;
var RESEARCH = 'research';
exports.RESEARCH = RESEARCH;
var QA = 'qa';
exports.QA = QA;
var STG = 'stg';
exports.STG = STG;
var PROD = 'prod';
exports.PROD = PROD;