'use client';

import Box from '@mui/material/Box';
import { useState } from 'react';
import AccordionItem from './accordionItem';

export default function Accordion({ items, setImageIndex, isMultiOpen, analyticsLocation }) {
  const [expandedDrawerReg, setExpandedDrawerReg] = useState('');

  return (
    <Box data-testid='accordion' gridColumn={{ xs: '3 / 13', md: '3 / 8' }}>
      <Box>
        {items?.sort()?.map((accItem, index) => (
          <AccordionItem
            key={`accordionItem-${accItem.questionNumber}`}
            text={accItem}
            analyticsLocation={analyticsLocation}
            index={index}
            setImageIndex={setImageIndex}
            expandedDrawerReg={expandedDrawerReg}
            setExpandedDrawerReg={setExpandedDrawerReg}
            isMultiOpen={isMultiOpen}
          />
        ))}
      </Box>
    </Box>
  );
}
