'use client';

import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

const StyledNormalCheckbox = styled(Checkbox)(({ theme }) => ({
  appearance: 'none',
  webkitAppearance: 'none',
  backgroundColor: 'white',
  margin: '0',
  color: 'white',
  width: '24px',
  height: '24px',
  transform: 'translateY(-2px)',
  display: 'grid',
  placeContent: 'center',
  boxSizing: 'border-box',
  '&.Mui-checked': {
    border: '1px',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderColor: theme.palette.colorTheme.backgroundPrimaryDark,
  },
}));

const NormalCheckbox = styled(Checkbox, { shouldForwardProp: (prop) => prop !== 'error' })(
  ({ theme, error = false }) => ({
    color: error ? theme.palette.colorTheme.errorDark : theme.palette.colorTheme.backgroundPrimaryDark,
    '&.Mui-checked': {
      color: theme.palette.colorTheme.backgroundPrimaryDark,
    },
    width: '24px',
    height: '24px',
    ariaRoleDescription: 'checkbox',
  }),
);

export { StyledNormalCheckbox, NormalCheckbox };
