'use client';

export default function AngleRight() {
  return (
    <svg width='10' height='17' viewBox='0 0 10 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Group'>
        <g id='Regular'>
          <path
            id='Shape'
            d='M1.66699 1L8.81366 8.14667C8.90746 8.24033 8.96016 8.36745 8.96016 8.5C8.96016 8.63255 8.90746 8.75967 8.81366 8.85333L1.66699 16'
            stroke='#28393D'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  );
}
