'use client';

export default function FacebookLogo() {
  return (
    <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.7 31H2.83333C2.47971 31 2.14057 30.8595 1.89052 30.6095C1.64048 30.3594 1.5 30.0203 1.5 29.6667V2.33333C1.5 1.97971 1.64048 1.64057 1.89052 1.39052C2.14057 1.14048 2.47971 1 2.83333 1H30.1667C30.5203 1 30.8594 1.14048 31.1095 1.39052C31.3595 1.64057 31.5 1.97971 31.5 2.33333V29.6667C31.5 30.0203 31.3595 30.3594 31.1095 30.6095C30.8594 30.8595 30.5203 31 30.1667 31H22.0333V19.6667H25.4467C25.6105 19.6679 25.7689 19.6087 25.8919 19.5005C26.0149 19.3923 26.0937 19.2426 26.1133 19.08L26.62 15.08C26.6314 14.986 26.6226 14.8906 26.5942 14.8003C26.5658 14.7099 26.5185 14.6266 26.4554 14.556C26.3924 14.4853 26.315 14.4289 26.2284 14.3905C26.1418 14.3522 26.048 14.3326 25.9533 14.3333H22.0333V12.92C22.0333 12.6241 22.0916 12.3311 22.2049 12.0577C22.3181 11.7843 22.4841 11.5359 22.6933 11.3267C22.9026 11.1174 23.151 10.9514 23.4244 10.8382C23.6977 10.725 23.9908 10.6667 24.2867 10.6667H26.7C26.8768 10.6667 27.0464 10.5964 27.1714 10.4714C27.2964 10.3464 27.3667 10.1768 27.3667 10V6C27.3667 5.82319 27.2964 5.65362 27.1714 5.5286C27.0464 5.40357 26.8768 5.33333 26.7 5.33333H24.2867C22.2746 5.33333 20.3449 6.13264 18.9221 7.55542C17.4993 8.97819 16.7 10.9079 16.7 12.92V14.3333H13.3667C13.1899 14.3333 13.0203 14.4036 12.8953 14.5286C12.7702 14.6536 12.7 14.8232 12.7 15V19C12.7 19.1768 12.7702 19.3464 12.8953 19.4714C13.0203 19.5964 13.1899 19.6667 13.3667 19.6667H16.7V31Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
