'use client';

export default function SleepIcon({ color = '#0A89FF', width = '50', height = '42' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 50 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M44.5625 21C44.5625 31.8041 35.8041 40.5625 25 40.5625C14.1959 40.5625 5.4375 31.8041 5.4375 21C5.4375 10.1959 14.1959 1.4375 25 1.4375C35.8041 1.4375 44.5625 10.1959 44.5625 21Z'
        stroke={color}
        strokeWidth='1.5'
      />
      <path d='M1.5625 13.1875L48.4375 13.1875' stroke={color} strokeWidth='1.5' strokeLinecap='square' />
    </svg>
  );
}
