'use client';

import { Box, Typography } from '@mui/material';
import { ButtonOnLightOutline } from '../../buttons';

export default function TextAndButtonVariantRedirect({
  handleClose,
  title,
  subtitle,
  buttonText,
  secondButtonText,
  redirectUrl,
}) {
  const handleRedirect = () => {
    window.location.href = redirectUrl;
  };

  return (
    <>
      <Typography variant='h1' color='colorTheme.contentPrimaryOnLight'>
        {title}
      </Typography>
      <Typography variant='bodyXS' marginTop='16px' color='colorTheme.contentPrimaryOnLight'>
        {subtitle}
      </Typography>
      <Box
        display='flex'
        columnGap={{
          xs: 2,
          sm: 3,
        }}
      >
        <ButtonOnLightOutline
          sx={{ marginTop: '40px' }}
          onClick={() => handleRedirect()}
          data-testid='keepExploringButtonTwo'
        >
          <Typography variant='buttonL' color='colorTheme.contentPrimaryOnLight'>
            {buttonText}
          </Typography>
        </ButtonOnLightOutline>
        <ButtonOnLightOutline
          sx={{ marginTop: '40px' }}
          onClick={() => handleClose()}
          data-testid='keepExploringButtonTwo'
        >
          <Typography variant='buttonL' color='colorTheme.contentPrimaryOnLight'>
            {secondButtonText}
          </Typography>
        </ButtonOnLightOutline>
      </Box>
    </>
  );
}
