'use client';

import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

export default function CookieConsent({ checkout }) {
  const [hasMounted, setHasMounted] = useState(false);
  const textClass = checkout ? 'text-blue-900' : 'text-blue-100';
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }
  return (
    <Box id='cookieLink' data-testid='cookieConsent'>
      <Typography
        id='teconsent'
        variant='bodyS'
        data-analytics-action='Cookie Policy'
        className={textClass}
      />
    </Box>
  );
}
