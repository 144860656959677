'use client';

export default function SocialInstagram({ height = '32', width = '32', color = '#F1EEE6' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.6576 5.33337H11.3242C8.01025 5.33337 5.32422 8.01941 5.32422 11.3334V20.6667C5.32422 23.9807 8.01025 26.6667 11.3242 26.6667H20.6576C23.9715 26.6667 26.6576 23.9807 26.6576 20.6667V11.3334C26.6576 8.01941 23.9715 5.33337 20.6576 5.33337ZM11.3242 6.66671H20.6576C23.2351 6.66671 25.3242 8.75579 25.3242 11.3334V20.6667C25.3242 23.2443 23.2351 25.3334 20.6576 25.3334H11.3242C8.74663 25.3334 6.65755 23.2443 6.65755 20.6667V11.3334C6.65755 8.75579 8.74663 6.66671 11.3242 6.66671ZM10.8798 16C10.8798 13.1776 13.1685 10.8889 15.9909 10.8889C18.8133 10.8889 21.102 13.1776 21.102 16C21.102 18.8225 18.8133 21.1112 15.9909 21.1112C13.1685 21.1112 10.8798 18.8225 10.8798 16ZM19.7687 16C19.7687 13.914 18.0769 12.2223 15.9909 12.2223C13.9049 12.2223 12.2131 13.914 12.2131 16C12.2131 18.0861 13.9049 19.7778 15.9909 19.7778C18.0769 19.7778 19.7687 18.0861 19.7687 16ZM23.1822 9.7718C23.1136 9.22402 22.6461 8.80004 22.0798 8.80004C21.4663 8.80004 20.9687 9.29763 20.9687 9.91115C20.9687 10.5247 21.4663 11.0223 22.0798 11.0223C22.6933 11.0223 23.1909 10.5247 23.1909 9.91115L23.1822 9.7718Z'
        fill={color}
      />
    </svg>
  );
}
