'use client';

/* eslint-disable no-mixed-operators */
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import EnergyIcon from '../icons/energyIcon';
import MoodIcon from '../icons/moodIcon';
import FocusIcon from '../icons/focusIcon';
import SleepIcon from '../icons/sleepIcon';
import HungerIcon from '../icons/hungerIcon';
import MinimizeIconCustom from '../icons/minimizeIconCustom';
import AddIconCustom from '../icons/addIconCustom';

const iconsMap = {
  0: <EnergyIcon />,
  1: <HungerIcon />,
  2: <MoodIcon />,
  3: <SleepIcon />,
  4: <FocusIcon />,
};

function CollapsibleRow({ items, analyticsLocation, showOpenCloseIcons = false, forwardedRef }) {
  const isLargeScreenSize = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [openItemId, setOpenItemId] = useState(0);

  const { benefitsTitle, benefitsSubtext, drawers } = items;

  useEffect(() => {
    if (isLargeScreenSize) {
      setOpenItemId(0);
    }
  }, [isLargeScreenSize]);

  const handleToggle = (itemId) => {
    setOpenItemId((prevOpenItemId) => (prevOpenItemId === itemId ? null : itemId));
  };
  const renderTextWithBoldPhrases = (content) => {
    const phrasesToBold = [
      '20% less fatigue',
      'feeling hungry and just a passing craving',
      '55% higher report of poor mood',
      'lead to better sleep',
    ];

    const boldedText = phrasesToBold.reduce(
      (result, phrase) =>
        result.replace(
          new RegExp(phrase, 'gi'),
          `<span style="font-weight: 700; font-family: MatterSemibold, Arial, sans-serif;">
            ${phrase}
          </span>`,
        ),
      content,
    );

    // TODO: figure out a system for random bolded phrases throughout app

    return (
      <Typography
        variant='bodyM'
        color='colorTheme.contentPrimaryOnLight'
        component='span'
        display='block'
        dangerouslySetInnerHTML={{ __html: boldedText }}
      />
    );
  };

  return (
    <>
      <Box id='benefits' gridColumn={{ xs: '2/-2', sm: '3/-3', lg: '2/7' }}>
        <Box
          className='border-line'
          height='4px'
          backgroundColor='black'
          width='100%'
          margin={{ xs: '68px 0 16px 0', sm: '84px 0 18px 0', lg: '110px 0 18px 0' }}
        />
        <Typography
          className='title'
          data-testid='benefitTitle'
          variant='h3'
          color='colorTheme.contentPrimaryOnLight'
          ref={forwardedRef}
        >
          {benefitsTitle}
        </Typography>
        <Typography
          className='subtext'
          data-testid='benefitSubtext'
          variant='bodyM'
          color='colorTheme.contentPrimaryOnLight'
          margin={{ xs: '35px 0 32px 0', sm: '64px 0 74px 0', lg: '38px 0 64px 0' }}
          display='block'
        >
          {benefitsSubtext}
        </Typography>
      </Box>
      <Box
        gridColumn={{ xs: '2/-2', sm: '3/-3', lg: '2/-2' }}
        display='flex'
        gap='0 45px'
        flex='1 1 0px'
        flexDirection={{ xs: 'column', lg: 'row' }}
        alignItems={{ xs: 'none', lg: 'baseline' }}
        marginBottom={{ xs: '64px', lg: '0' }}
      >
        {drawers.drawersList.map((item) => (
          <Box
            key={item.id}
            flex='1 1 0px'
            onClick={() => handleToggle(item.id)}
            paddingBottom={{ xs: '0', lg: '64px' }}
          >
            <Box
              display='flex'
              alignItems={{ xs: 'center', lg: 'baseline' }}
              gap={{ xs: '0 16px' }}
              flexDirection={{ xs: 'row', lg: 'column' }}
              sx={{
                cursor: 'pointer',
              }}
              data-analytics-location={analyticsLocation}
              data-analytics-action={item.title}
            >
              <Box width='50px' height='auto' margin={{ xs: '24px 0', lg: '0 0 30px 0' }}>
                {iconsMap[item.id]}
              </Box>
              <Box>
                <Typography
                  data-testid={`title-${item.id}`}
                  variant='h4'
                  color={
                    item.id === openItemId
                      ? 'colorTheme.contentActionOnLight'
                      : 'colorTheme.contentPrimaryOnLight'
                  }
                >
                  {item.title}
                </Typography>
              </Box>
              {!isLargeScreenSize && showOpenCloseIcons && (
                <Box width='24px' display='flex' marginLeft='auto' data-testid='benefitsOpenCloseIcon'>
                  {item.id === openItemId ? (
                    <MinimizeIconCustom paddingLeft='0px' width='24px' />
                  ) : (
                    <AddIconCustom />
                  )}
                </Box>
              )}
            </Box>
            {(isLargeScreenSize || item.id !== openItemId) && (
              <Box
                height='1px'
                backgroundColor={
                  item.id === openItemId
                    ? 'colorTheme.strokePrimaryOnLightDark'
                    : 'colorTheme.strokePrimaryOnLightLight'
                }
                width='100%'
                margin={{ xs: '0', lg: '28px 0' }}
              />
            )}

            {isLargeScreenSize && (
              <Fade in={item.id === openItemId} timeout={300}>
                <Box marginTop={{ xs: '20px' }}>
                  <Typography
                    data-testid={`body-${item.id}`}
                    variant='bodyM'
                    color='colorTheme.containerPrimaryOnLight'
                  >
                    {renderTextWithBoldPhrases(item.body)}
                  </Typography>
                </Box>
              </Fade>
            )}

            {!isLargeScreenSize && (
              <Collapse in={item.id === openItemId} timeout={300} collapsedSize={0}>
                <Box marginTop={{ xs: '20px' }}>
                  <Typography
                    data-testid={`body-${item.id}`}
                    variant='bodyM'
                    color='colorTheme.containerPrimaryOnLight'
                  >
                    {renderTextWithBoldPhrases(item.body)}
                  </Typography>
                </Box>
              </Collapse>
            )}

            {item.id === openItemId && (
              <Box
                height='1px'
                backgroundColor={
                  item.id === openItemId
                    ? 'colorTheme.strokePrimaryOnLightDark'
                    : 'colorTheme.strokePrimaryOnLightLight'
                }
                width='100%'
                margin={{ xs: '16px 0 0 0', lg: '28px 0' }}
                display={isLargeScreenSize ? 'none' : 'block'}
              />
            )}
          </Box>
        ))}
      </Box>
    </>
  );
}

export default CollapsibleRow;
