'use client';

export default function TwitterLogo() {
  return (
    <svg width='33' height='26' viewBox='0 0 33 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M31.4074 5.94674L28.6074 4.74674L30.0741 1.54674L26.6608 2.68008C25.5736 1.66665 24.1469 1.096 22.6608 1.08008C21.0705 1.0836 19.5465 1.71687 18.422 2.84133C17.2975 3.96578 16.6643 5.48986 16.6608 7.08008V8.41341C11.9408 9.38674 7.82076 6.81341 3.99409 2.41341C3.32742 5.96897 3.99409 8.63563 5.99409 10.4134L1.60742 9.74674C1.7769 11.159 2.43721 12.4668 3.47299 13.4417C4.50878 14.4165 5.85416 14.9964 7.27409 15.0801L3.60742 16.4134C4.94076 19.0801 7.36742 19.4934 10.6074 19.7467C7.95918 21.553 4.81219 22.4854 1.60742 22.4134C18.6208 29.9734 28.6074 18.8667 28.6074 9.08008V7.97341L31.4074 5.94674Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
