'use client';

export default function GooglePayCard({ width = 52, height = 24 }) {
  return (
    <svg
      data-testid='googlePayCardIcon'
      width={width}
      height={height}
      viewBox='0 0 52 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='51' height='23' rx='3.5' fill='white' />
      <path
        d='M25.233 11.9962V15.3927H24.2469V6.99268H26.8108C27.4353 6.99268 28.027 7.24833 28.4871 7.72311C28.9473 8.16138 29.1774 8.81877 29.1774 9.51269C29.1774 10.2066 28.9473 10.8275 28.4871 11.3023C28.027 11.777 27.4682 12.0327 26.8108 12.0327L25.233 11.9962ZM25.233 8.01529V10.937H26.8765C27.2381 10.937 27.5997 10.791 27.8297 10.4988C28.3557 9.95095 28.3557 9.0379 27.8626 8.49007L27.8297 8.45355C27.5668 8.16138 27.2381 7.97877 26.8765 8.01529H25.233Z'
        fill='#5F6368'
      />
      <path
        d='M31.4454 9.47632C32.1685 9.47632 32.7273 9.69545 33.1546 10.1337C33.5819 10.572 33.7791 11.1563 33.7791 11.8868V15.3929H32.8588V14.5894H32.8259C32.4315 15.2468 31.8727 15.5755 31.2153 15.5755C30.6565 15.5755 30.1635 15.3929 29.769 15.0276C29.4075 14.6624 29.1774 14.1511 29.1774 13.6033C29.1774 13.0189 29.3746 12.5442 29.769 12.1789C30.1635 11.8137 30.7222 11.6676 31.3796 11.6676C31.9713 11.6676 32.4315 11.7772 32.793 12.0329V11.7772C32.793 11.412 32.6616 11.0468 32.3986 10.8276C32.1356 10.572 31.8069 10.4259 31.4454 10.4259C30.8866 10.4259 30.4593 10.6815 30.1635 11.1928L29.3088 10.6085C29.8348 9.84154 30.525 9.47632 31.4454 9.47632ZM30.1963 13.6398C30.1963 13.932 30.3278 14.1876 30.525 14.3337C30.7551 14.5163 31.0181 14.6259 31.281 14.6259C31.6755 14.6259 32.0699 14.4433 32.3657 14.1146C32.6944 13.7859 32.8588 13.3842 32.8588 12.9459C32.5629 12.6902 32.1356 12.5442 31.5769 12.5442C31.1824 12.5442 30.8537 12.6537 30.5908 12.8729C30.3278 13.0555 30.1963 13.3111 30.1963 13.6398Z'
        fill='#5F6368'
      />
      <path
        d='M39.104 9.65894L35.8499 17.9494H34.8638L36.08 15.0642L33.9435 9.69546H34.9953L36.5402 13.8224H36.5731L38.0851 9.69546H39.104V9.65894Z'
        fill='#5F6368'
      />
      <path
        d='M21.3214 11.2659C21.3214 10.9372 21.2886 10.6085 21.2557 10.2798H17.147V12.1424H19.4807C19.3821 12.7268 19.0863 13.2746 18.6261 13.6033V14.8085H20.0395C20.8612 13.9685 21.3214 12.7268 21.3214 11.2659Z'
        fill='#4285F4'
      />
      <path
        d='M17.1471 15.9772C18.3304 15.9772 19.3165 15.5389 20.0396 14.8085L18.6262 13.6033C18.2318 13.8954 17.7387 14.078 17.1471 14.078C16.0295 14.078 15.0434 13.238 14.7147 12.0693H13.2684V13.3111C14.0244 14.9546 15.5036 15.9772 17.1471 15.9772Z'
        fill='#34A853'
      />
      <path
        d='M14.7147 12.0692C14.5175 11.4849 14.5175 10.8275 14.7147 10.2066V8.96484H13.2684C12.6439 10.3162 12.6439 11.9231 13.2684 13.3109L14.7147 12.0692Z'
        fill='#FBBC04'
      />
      <path
        d='M17.147 8.23435C17.7715 8.23435 18.3632 8.49001 18.8234 8.96479L20.0724 7.57696C19.2836 6.77348 18.2317 6.29869 17.1799 6.33522C15.5364 6.33522 14.0244 7.35783 13.3013 9.00131L14.7475 10.2431C15.0434 9.07436 16.0295 8.23435 17.147 8.23435Z'
        fill='#EA4335'
      />
      <rect x='0.5' y='0.5' width='51' height='23' rx='3.5' stroke='#E4E2DA' />
    </svg>
  );
}
