'use client';

export default function Calendar({ color = '#28393D', height = '30', width = '30' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.940002 6.1875C0.940002 5.35907 1.61158 4.6875 2.44 4.6875H27.565C28.3934 4.6875 29.065 5.35907 29.065 6.1875V27.5625C29.065 28.3909 28.3934 29.0625 27.565 29.0625H2.44C1.61158 29.0625 0.940002 28.3909 0.940002 27.5625V6.1875Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.940002 12.1875H29.065'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.44 7.5V0.9375'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.565 7.5V0.9375'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
