'use client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import ExclamationTriangle from '../icons/exclamationTriangle';
import theme from '../theme/theme';

export default function IconAndInfoCard({
  cardTitle,
  icon,
  lineOne,
  lineTwo,
  editLink,
  isInvalid = false,
  darkText = false,
  invalidMessage,
  testId,
}) {
  let pageLinkPrefix = '/';
  if (globalThis && globalThis.location && globalThis.location.pathname) {
    pageLinkPrefix = globalThis.location.pathname.includes('app') ? '/app/' : '/';
  }

  return (
    <Box data-testid={`${testId}Box`}>
      {cardTitle && (
        <Typography
          variant='h4'
          p='24px 0px 16px 0px'
          color='theme.palette.colorTheme.contentPrimaryOnLight'
          data-testid={`${testId}Title`}
        >
          {cardTitle}
        </Typography>
      )}
      <Box
        border={isInvalid ? `2px ${theme.palette.colorTheme.errorDark} solid` : 'none'}
        display='flex'
        flexDirection='row'
        minHeight={{ xs: '105px', md: '115px' }}
        backgroundColor='colorTheme.containerPrimaryOnDark'
        justifyContent='space-between'
        padding='24px'
        alignItems='center'
      >
        <Box display='flex' flexDirection='row' alignItems='center'>
          {isInvalid ? (
            <Box pr='16px' data-testid={`${testId}InvalidIcon`}>
              <ExclamationTriangle color={theme.palette.colorTheme.errorDark} width='25' height='25' />
            </Box>
          ) : (
            <Box
              pr='16px'
              maxHeight='55px'
              minWidth='55px'
              maxWidth='55px'
              justifyContent='center'
              display='flex'
              data-testid={`${testId}RegularIcon`}
            >
              {icon}
            </Box>
          )}
          {isInvalid ? (
            <Typography
              variant='bodyM'
              color='theme.palette.colorTheme.contentPrimaryOnLight'
              data-testid={`${testId}InvalidMessage`}
            >
              {invalidMessage}
            </Typography>
          ) : (
            <Box display='flex' flexDirection='column' data-testid={`${testId}RegularText`}>
              <Typography
                variant={darkText ? 'bodyMSemibold' : 'bodyM'}
                color='theme.palette.colorTheme.contentPrimaryOnLight'
                data-testid={`${testId}LineOne`}
              >
                {lineOne}
              </Typography>
              <Typography
                marginTop='4px'
                variant='bodyM'
                color={
                  darkText
                    ? 'theme.palette.colorTheme.contentPrimaryOnLight'
                    : 'colorTheme.contentSecondaryOnLightWeb'
                }
                data-testid={`${testId}LineTwo`}
              >
                {lineTwo}
              </Typography>
            </Box>
          )}
        </Box>
        {editLink && (
          <Link
            style={{
              color: theme.palette.colorTheme.contentPrimaryOnLight,
              textDecoration: 'underline',
              marginLeft: '16px',
            }}
            href={`${pageLinkPrefix}${editLink}`}
            data-testid={`${testId}EditLink`}
          >
            Edit
          </Link>
        )}
      </Box>
    </Box>
  );
}
