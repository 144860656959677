'use client';

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function CustomDatePicker({
  minNextOrderDate,
  maxNextOrderDate,
  nextOrderDate,
  setNextOrderDate,
  testId,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        disableHighlightToday
        minDate={minNextOrderDate}
        maxDate={maxNextOrderDate}
        value={nextOrderDate}
        onChange={(newValue) => setNextOrderDate(newValue)}
        data-testid={testId}
        sx={{
          color: 'colorTheme.contentPrimaryOnLight',
          svg: {
            color: 'colorTheme.contentPrimaryOnLight',
          },
          span: {
            color: 'colorTheme.contentPrimaryOnLight',
          },
          button: {
            color: 'colorTheme.contentPrimaryOnLight',
          },
          'button.Mui-selected': {
            color: 'colorTheme.contentPrimaryOnDark',
            backgroundColor: 'colorTheme.containerPrimaryOnLight',
            '&:focus': {
              backgroundColor: 'colorTheme.containerPrimaryOnLight',
            },
            '&:hover': {
              backgroundColor: 'colorTheme.containerPrimaryOnLight',
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}
