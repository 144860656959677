'use client';

export default function ChargingBatteryEmpty({ color = '#28393D', height = '28', width = '17' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 28 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.6888 14.5637H21.5C22.5355 14.5637 23.375 13.7243 23.375 12.6887V10.8137H25.25C26.2855 10.8137 27.125 9.97425 27.125 8.93872V7.06372C27.125 6.02819 26.2855 5.18872 25.25 5.18872H23.375V3.31372C23.375 2.27819 22.5355 1.43872 21.5 1.43872H18.6875'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.5 1.43872H2.75C1.71447 1.43872 0.875 2.27819 0.875 3.31372V12.6887C0.875 13.7243 1.71447 14.5637 2.75 14.5637H6.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.0013 0.501221L6.50125 8.93872H10.2513V15.5012L17.7513 7.06372H14.0013V0.501221V0.501221Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
