'use client';

export default function AngleDown() {
  return (
    <svg
      width='14'
      height='8'
      viewBox='0 0 14 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid='angleDown'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.60161 7.32343L1.16411 1.80156C0.9438 1.58125 0.9438 1.225 1.16411 1.00468L1.49692 0.671871C1.71724 0.451558 2.07349 0.451558 2.2938 0.671871L7.00005 5.46718L11.7063 0.676558C11.9266 0.456246 12.2829 0.456246 12.5032 0.676558L12.836 1.00937C13.0563 1.22968 13.0563 1.58593 12.836 1.80625L7.39849 7.32812C7.17817 7.54375 6.82192 7.54375 6.60161 7.32343Z'
        fill='#28393D'
      />
    </svg>
  );
}
