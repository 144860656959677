'use client';

import Box from '@mui/material/Box';
import LOCATION_PATHS from '@lingo/adobe-analytics';
import Accordion from './accordion';
import SectionTitleText from './sectionTitleText';

export default function FAQSection({ data, elementId }) {
  return (
    <Box
      display='grid'
      gridTemplateColumns={elementId === 'pdpDrawers' ? 'none' : 'repeat(14, 1fr)'}
      columnGap={{
        xs: 1,
        md: 3,
        lg: 6,
      }}
      gridColumn={data.sectionTitle ? { xs: '2 / -2', sm: '3 / 13', lg: '1/-1' } : '1/-1'}
      id={elementId}
      marginTop={data.sectionTitle ? '120px' : '48px'}
    >
      <>
        {!!data.sectionTitle && (
          <SectionTitleText
            gridColumn={{ xs: '1/-1', lg: '3 / 7' }}
            text={{ sectionTitleBold: data.sectionTitle }}
            dir='ltr'
            data-testid='faqSection'
          />
        )}
        <Box gridColumn={data.sectionTitle ? { xs: '1/-1', lg: '8 / 13' } : '1/-1'}>
          <Accordion
            items={data?.allFaqItems?.items}
            isMultiOpen
            analyticsLocation={LOCATION_PATHS.faq}
            setHeight={!!data.sectionTitle}
          />
        </Box>
      </>
    </Box>
  );
}
