'use client';

export default function UkCaMark({ color = 'white', width = '20', height = '20' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 155 155' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.375 19.375V55.7031C19.375 60.1994 21.1611 64.5115 24.3405 67.6908C27.5198 70.8701 31.8319 72.6562 36.3281 72.6562H55.7031C60.1994 72.6562 64.5115 70.8701 67.6908 67.6908C70.8701 64.5115 72.6562 60.1994 72.6562 55.7031V19.375H60.5469V55.7031C60.5469 56.9878 60.0366 58.2198 59.1282 59.1282C58.2198 60.0366 56.9878 60.5469 55.7031 60.5469H36.3281C35.0435 60.5469 33.8115 60.0366 32.9031 59.1282C31.9947 58.2198 31.4844 56.9878 31.4844 55.7031V19.375H19.375Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M82.3438 19.375V72.6562H94.4531V53.2812L118.672 72.6562H135.625L101.719 46.0156L135.625 19.375H118.672L94.4531 38.75V19.375H82.3438Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M72.6562 82.3438V94.4531H36.3281C35.0435 94.4531 33.8115 94.9634 32.9031 95.8718C31.9947 96.7802 31.4844 98.0122 31.4844 99.2969V118.672C31.4844 119.957 31.9947 121.189 32.9031 122.097C33.8115 123.005 35.0435 123.516 36.3281 123.516H72.6562V135.625H36.3281C31.8319 135.625 27.5198 133.839 24.3405 130.66C21.1611 127.48 19.375 123.168 19.375 118.672V99.2969C19.375 94.8006 21.1611 90.4885 24.3405 87.3092C27.5198 84.1299 31.8319 82.3438 36.3281 82.3438H72.6562Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M82.3438 135.625V99.2969C82.3438 94.8006 84.1299 90.4885 87.3092 87.3092C90.4885 84.1299 94.8006 82.3438 99.2969 82.3438H118.672C123.168 82.3438 127.48 84.1299 130.66 87.3092C133.839 90.4885 135.625 94.8006 135.625 99.2969V135.625H123.516V123.516H94.4531V135.625H82.3438ZM94.4531 111.406H123.516V99.2969C123.516 98.0122 123.005 96.7802 122.097 95.8718C121.189 94.9634 119.957 94.4531 118.672 94.4531H99.2969C98.0122 94.4531 96.7802 94.9634 95.8718 95.8718C94.9634 96.7802 94.4531 98.0122 94.4531 99.2969V111.406Z'
        fill={color}
      />
    </svg>
  );
}
