"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getUrlForEnvAndApp;
var _environments = require("../environments");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function getUrlForEnvAndApp(environment, appName) {
  var _urls, _urls$environment;
  var urls = (_urls = {}, _defineProperty(_urls, _environments.LOCAL, "https://".concat(appName === 'home' ? 'local' : 'local-shop', ".hellolingo.com")), _defineProperty(_urls, _environments.RESEARCH, "https://".concat(appName === 'home' ? 'research' : 'shop-r', ".hellolingo.com")), _defineProperty(_urls, _environments.DEV, "https://".concat(appName === 'home' ? 'dev' : 'shop-d', ".hellolingo.com")), _defineProperty(_urls, _environments.QA, "https://".concat(appName === 'home' ? 'qa' : 'shop-q', ".hellolingo.com")), _defineProperty(_urls, _environments.STG, "https://".concat(appName === 'home' ? 'stg' : 'shop-s', ".hellolingo.com")), _defineProperty(_urls, _environments.PROD, "https://".concat(appName === 'home' ? 'www' : 'shop', ".hellolingo.com")), _urls);

  // Default to production url if environment isn't defined correctly
  return (_urls$environment = urls[environment]) !== null && _urls$environment !== void 0 ? _urls$environment : urls[_environments.PROD];
}