'use client';

export default function PrivacyIcon({ color = '#002A3A', width = '24', height = '24' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5 9C9.77817 9 11.625 7.15317 11.625 4.875C11.625 2.59683 9.77817 0.75 7.5 0.75C5.22183 0.75 3.375 2.59683 3.375 4.875C3.375 7.15317 5.22183 9 7.5 9Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.21 12.4252C10.2696 10.5256 7.3791 9.97534 4.87645 11.029C2.37381 12.0827 0.747385 14.5348 0.750003 17.2502'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 17.5C13 16.6716 13.6716 16 14.5 16H21.5C22.3284 16 23 16.6716 23 17.5V21.5C23 22.3284 22.3284 23 21.5 23H14.5C13.6716 23 13 22.3284 13 21.5V17.5Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.75 11.25V11.25C16.0931 11.25 14.75 12.5931 14.75 14.25V15.75H20.75V14.25C20.75 12.5931 19.4069 11.25 17.75 11.25Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.3783 18.5L17.8679 20.5142C17.8 20.6046 17.6962 20.661 17.5834 20.669C17.4706 20.677 17.3599 20.6357 17.2799 20.5558L16.5 19.7759'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
