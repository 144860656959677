'use client';

export default function ApplePayCard({ width = 52, height = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 52 24'
      data-testid='applePayCardIcon'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='51' height='23' rx='3.5' fill='white' />
      <path
        d='M16.6731 7.5471C16.3306 7.95243 15.7826 8.27212 15.2345 8.22645C15.166 7.6784 15.4343 7.0961 15.7483 6.73644C16.0908 6.3197 16.6903 6.02284 17.1755 6C17.2326 6.57088 17.01 7.13035 16.6731 7.5471ZM17.1698 8.33492C16.3763 8.28925 15.6969 8.78592 15.3201 8.78592C14.9377 8.78592 14.3611 8.35775 13.7331 8.36917C12.9167 8.38059 12.1574 8.84301 11.7407 9.57945C10.8844 11.0523 11.5181 13.2331 12.3458 14.432C12.7512 15.0257 13.2364 15.6765 13.8758 15.6537C14.4809 15.6308 14.7207 15.2598 15.4514 15.2598C16.1879 15.2598 16.3991 15.6537 17.0385 15.6422C17.7007 15.6308 18.1175 15.0485 18.5228 14.4548C18.9852 13.7812 19.1736 13.1246 19.185 13.0904C19.1736 13.079 17.9063 12.5937 17.8948 11.1323C17.8834 9.91056 18.8939 9.32826 18.9396 9.29401C18.3687 8.4491 17.4781 8.35775 17.1698 8.33492ZM21.754 6.67935V15.5794H23.1356V12.5366H25.048C26.7949 12.5366 28.0223 11.3378 28.0223 9.60228C28.0223 7.86679 26.8178 6.67935 25.0937 6.67935H21.754ZM23.1356 7.84396H24.7283C25.9272 7.84396 26.6123 8.48335 26.6123 9.60799C26.6123 10.7326 25.9272 11.3777 24.7226 11.3777H23.1356V7.84396ZM30.5456 15.648C31.4134 15.648 32.2183 15.2084 32.5837 14.5119H32.6122V15.5794H33.891V11.1494C33.891 9.86489 32.8634 9.03711 31.2821 9.03711C29.8149 9.03711 28.7302 9.87631 28.6903 11.0295H29.9348C30.0376 10.4814 30.5456 10.1218 31.2421 10.1218C32.087 10.1218 32.5609 10.5157 32.5609 11.2407V11.7317L30.8368 11.8344C29.2326 11.9315 28.3649 12.588 28.3649 13.7298C28.3649 14.883 29.2612 15.648 30.5456 15.648ZM30.9167 14.5918C30.1803 14.5918 29.7122 14.2379 29.7122 13.6955C29.7122 13.1361 30.1632 12.8107 31.0252 12.7593L32.5609 12.6622V13.1646C32.5609 13.9981 31.853 14.5918 30.9167 14.5918ZM35.598 18C36.9453 18 37.5789 17.4862 38.1327 15.9277L40.559 9.12274H39.1546L37.5276 14.3806H37.499L35.872 9.12274H34.4277L36.7683 15.6023L36.6427 15.9962C36.4315 16.6641 36.0889 16.921 35.4781 16.921C35.3696 16.921 35.1584 16.9096 35.0728 16.8982V17.9657C35.1527 17.9886 35.4952 18 35.598 18Z'
        fill='black'
      />
      <rect x='0.5' y='0.5' width='51' height='23' rx='3.5' stroke='#E4E2DA' />
    </svg>
  );
}
