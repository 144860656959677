'use client';

/* eslint-disable react/jsx-no-useless-fragment */
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ButtonOnLight } from '../buttons';

export default function SectionTitleText({
  style,
  text,
  color = 'colorTheme.contentPrimaryOnLight',
  gridColumn = {
    xs: '2 / 14',
    md: '2 / 7',
  },
  gridRow,
  display,
}) {
  const {
    sectionTitle,
    sectionTitleBold,
    sectionHeadingLineOne,
    sectionHeadingLineTwo,
    sectionHeadingLineThree,
    sectionDescriptionHeadingBold,
    sectionDescriptionHeadingOne,
    sectionDescriptionHeadingTwo,
    sectionHeadingButtonText,
  } = text;

  // TODO: Refactor to use explicit IF conditions and remove unnecessary fragment
  return (
    <>
      {sectionTitleBold && (
        <Box
          data-testid='sectionTitleContainer'
          sx={style}
          gridColumn={gridColumn}
          color={color}
          gridRow={gridRow}
          display={display}
          dir='ltr'
        >
          <Typography color='colorTheme.contentPrimaryOnLight' variant='bodyL' data-testid='sectionTitleText'>
            {sectionTitle}
          </Typography>
          <Divider
            sx={{
              borderColor: `${color}`,
              borderBottomWidth: '4px',
              margin: '0 0 16px 0',
            }}
          />
          {sectionHeadingLineOne && (
            <Typography color='colorTheme.contentPrimaryOnLight' variant='h2' data-testid='headings'>
              {sectionHeadingLineOne}
              <br />
              {sectionHeadingLineTwo}
              <br />
              {sectionHeadingLineThree}
            </Typography>
          )}
          {sectionDescriptionHeadingBold && (
            <Typography
              color='colorTheme.contentPrimaryOnLight'
              display='block'
              variant='bodyLMedium'
              data-testid='descriptionHeadingBold'
              sx={{
                paddingBottom: '60px',
              }}
            >
              {sectionDescriptionHeadingBold}
            </Typography>
          )}
          {!!sectionTitleBold && (
            <Typography
              color='colorTheme.contentPrimaryOnLight'
              display='block'
              variant='h3'
              data-testid='sectionTitleBold'
              sx={{
                paddingBottom: '0px',
                marginBottom: '40px',
              }}
            >
              {sectionTitleBold}
            </Typography>
          )}
          {!!sectionDescriptionHeadingOne && (
            <Typography
              color='colorTheme.contentPrimaryOnLight'
              display='block'
              variant='bodyL'
              data-testid='descriptionHeadingOne'
              sx={{
                paddingBottom: '60px',
              }}
            >
              {!!sectionDescriptionHeadingOne}
            </Typography>
          )}
          {!!sectionDescriptionHeadingTwo && (
            <Typography
              color='colorTheme.contentPrimaryOnLight'
              display='block'
              data-testid='descriptionHeadingTwo'
              variant='bodyL'
              sx={{
                paddingBottom: '60px',
              }}
            >
              {sectionDescriptionHeadingTwo}
            </Typography>
          )}
          {!!sectionHeadingButtonText && (
            <ButtonOnLight
              data-testid='seciongHeadingButtonText'
              onClick={null}
              sx={{ width: { xs: '100%', md: '321px' } }}
            >
              {sectionHeadingButtonText}
            </ButtonOnLight>
          )}
        </Box>
      )}
    </>
  );
}
