'use client';

export default function Biosensor({ color = '#28393D', width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid='biosensorIcon'
    >
      <path
        d='M1 21C1 26.3043 3.10714 31.3914 6.85786 35.1421C10.6086 38.8929 15.6957 41 21 41C26.3043 41 31.3914 38.8929 35.1421 35.1421C38.8929 31.3914 41 26.3043 41 21C41 15.6957 38.8929 10.6086 35.1421 6.85786C31.3914 3.10714 26.3043 1 21 1C15.6957 1 10.6086 3.10714 6.85786 6.85786C3.10714 10.6086 1 15.6957 1 21Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.4443 20.9999C17.4443 21.9429 17.8189 22.8473 18.4857 23.514C19.1525 24.1808 20.0569 24.5554 20.9999 24.5554C21.9429 24.5554 22.8473 24.1808 23.514 23.514C24.1808 22.8473 24.5554 21.9429 24.5554 20.9999C24.5554 20.0569 24.1808 19.1525 23.514 18.4857C22.8473 17.8189 21.9429 17.4443 20.9999 17.4443C20.0569 17.4443 19.1525 17.8189 18.4857 18.4857C17.8189 19.1525 17.4443 20.0569 17.4443 20.9999Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
