'use client';

export default function Truck({ color = '#002A3A', width = '24', height = '24' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.0256 5.44446H16.2478C16.5145 5.44446 16.7811 5.62223 16.9589 5.8L18.47 8.11112L20.5145 8.82224C20.87 8.91112 21.1367 9.26668 21.1367 9.62224V11.6667C21.1367 12.2 20.7811 12.5556 20.2478 12.5556H18.47M12.2478 12.5556H14.9204'
        stroke={color}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.6923 14.3333C17.6741 14.3333 18.4701 13.5374 18.4701 12.5555C18.4701 11.5737 17.6741 10.7778 16.6923 10.7778C15.7105 10.7778 14.9146 11.5737 14.9146 12.5555C14.9146 13.5374 15.7105 14.3333 16.6923 14.3333Z'
        stroke={color}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.24788 12.5556H2.02561C1.49228 12.5556 1.13672 12.2 1.13672 11.6667V9H12.6922V12.5556H7.80344'
        stroke={color}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.02558 14.3333C7.00742 14.3333 7.80336 13.5374 7.80336 12.5555C7.80336 11.5737 7.00742 10.7778 6.02558 10.7778C5.04374 10.7778 4.2478 11.5737 4.2478 12.5555C4.2478 13.5374 5.04374 14.3333 6.02558 14.3333Z'
        stroke={color}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.6923 1H2.02561C1.49227 1 1.13672 1.35556 1.13672 1.88889V9H12.6923V1Z'
        stroke={color}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.6924 5.44444H17.1368V4.02221C17.1368 3.3111 16.6924 2.6 15.9813 2.33333L12.6924 1V5.44444Z'
        stroke={color}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
