'use client';

export default function MinimizeIconCustom({
  width = '24',
  height = '24',
  stroke = '#002A3A',
  strokeWidth = '1.5',
  paddingLeft = '8px',
}) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <svg
        width={width}
        height={height}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ paddingLeft: { paddingLeft } }}
      >
        <g transform='translate(-4 0)'>
          <path
            d='M6.69666 17.3032L17.3033 6.69662'
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M6.69666 6.69678L17.3033 17.3034'
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </svg>
    </div>
  );
}
