'use client';

export default function PaypalCard({ width = 52, height = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 52 24'
      data-testid='paypalCardIcon'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='51' height='23' rx='3.5' fill='white' />
      <g clipPath='url(#clip0_1100_76205)'>
        <path
          d='M21.5429 8.75H19.2633C19.1073 8.75 18.9746 8.86333 18.9503 9.01733L18.0283 14.863C18.0099 14.9783 18.0993 15.0823 18.2163 15.0823H19.3046C19.4606 15.0823 19.5933 14.969 19.6176 14.8147L19.8663 13.238C19.8903 13.0837 20.0233 12.9703 20.1789 12.9703H20.9006C22.4023 12.9703 23.2689 12.2437 23.4953 10.8037C23.5973 10.1737 23.4996 9.67867 23.2046 9.332C22.8806 8.95133 22.3059 8.75 21.5429 8.75ZM21.8059 10.885C21.6813 11.703 21.0563 11.703 20.4519 11.703H20.1079L20.3493 10.1753C20.3636 10.083 20.4436 10.015 20.5369 10.015H20.6946C21.1063 10.015 21.4946 10.015 21.6953 10.2497C21.8149 10.3897 21.8516 10.5977 21.8059 10.885Z'
          fill='#253B80'
        />
        <path
          d='M28.3572 10.8585H27.2655C27.1725 10.8585 27.0922 10.9265 27.0778 11.0189L27.0295 11.3242L26.9532 11.2135C26.7168 10.8705 26.1898 10.7559 25.6638 10.7559C24.4575 10.7559 23.4272 11.6695 23.2265 12.9512C23.1222 13.5905 23.2705 14.2019 23.6332 14.6282C23.9658 15.0202 24.4418 15.1835 25.0082 15.1835C25.9802 15.1835 26.5192 14.5585 26.5192 14.5585L26.4705 14.8619C26.4522 14.9779 26.5415 15.0819 26.6578 15.0819H27.6412C27.7975 15.0819 27.9295 14.9685 27.9542 14.8142L28.5442 11.0779C28.5628 10.9629 28.4738 10.8585 28.3572 10.8585ZM26.8355 12.9832C26.7302 13.6069 26.2352 14.0255 25.6038 14.0255C25.2868 14.0255 25.0335 13.9239 24.8708 13.7312C24.7095 13.5399 24.6482 13.2675 24.6995 12.9642C24.7978 12.3459 25.3012 11.9135 25.9228 11.9135C26.2328 11.9135 26.4848 12.0165 26.6508 12.2109C26.8172 12.4072 26.8832 12.6812 26.8355 12.9832Z'
          fill='#253B80'
        />
        <path
          d='M34.1712 10.8574H33.0742C32.9695 10.8574 32.8712 10.9094 32.8118 10.9964L31.2988 13.2251L30.6575 11.0834C30.6172 10.9494 30.4935 10.8574 30.3535 10.8574H29.2755C29.1445 10.8574 29.0535 10.9854 29.0952 11.1088L30.3035 14.6548L29.1675 16.2584C29.0782 16.3848 29.1682 16.5584 29.3225 16.5584H30.4182C30.5222 16.5584 30.6195 16.5078 30.6785 16.4224L34.3272 11.1558C34.4145 11.0298 34.3248 10.8574 34.1712 10.8574Z'
          fill='#253B80'
        />
        <path
          d='M37.8032 8.75H35.5232C35.3675 8.75 35.2349 8.86333 35.2105 9.01733L34.2885 14.863C34.2702 14.9783 34.3595 15.0823 34.4759 15.0823H35.6459C35.7545 15.0823 35.8475 15.003 35.8645 14.895L36.1262 13.238C36.1502 13.0837 36.2832 12.9703 36.4389 12.9703H37.1602C38.6622 12.9703 39.5285 12.2437 39.7552 10.8037C39.8575 10.1737 39.7592 9.67867 39.4642 9.332C39.1405 8.95133 38.5662 8.75 37.8032 8.75ZM38.0662 10.885C37.9419 11.703 37.3169 11.703 36.7122 11.703H36.3685L36.6102 10.1753C36.6245 10.083 36.7039 10.015 36.7975 10.015H36.9552C37.3665 10.015 37.7552 10.015 37.9559 10.2497C38.0755 10.3897 38.1119 10.5977 38.0662 10.885Z'
          fill='#179BD7'
        />
        <path
          d='M44.617 10.8585H43.526C43.4323 10.8585 43.3526 10.9265 43.3386 11.0189L43.2903 11.3242L43.2136 11.2135C42.9773 10.8705 42.4506 10.7559 41.9246 10.7559C40.7183 10.7559 39.6883 11.6695 39.4876 12.9512C39.3836 13.5905 39.5313 14.2019 39.894 14.6282C40.2273 15.0202 40.7026 15.1835 41.269 15.1835C42.241 15.1835 42.78 14.5585 42.78 14.5585L42.7313 14.8619C42.713 14.9779 42.8023 15.0819 42.9193 15.0819H43.9023C44.058 15.0819 44.1906 14.9685 44.215 14.8142L44.8053 11.0779C44.8233 10.9629 44.734 10.8585 44.617 10.8585ZM43.0953 12.9832C42.9906 13.6069 42.495 14.0255 41.8636 14.0255C41.5473 14.0255 41.2933 13.9239 41.1306 13.7312C40.9693 13.5399 40.9086 13.2675 40.9593 12.9642C41.0583 12.3459 41.561 11.9135 42.1826 11.9135C42.4926 11.9135 42.7446 12.0165 42.9106 12.2109C43.0776 12.4072 43.1436 12.6812 43.0953 12.9832Z'
          fill='#179BD7'
        />
        <path
          d='M45.9044 8.91067L44.9687 14.8633C44.9504 14.9787 45.0397 15.0827 45.156 15.0827H46.0967C46.253 15.0827 46.3857 14.9693 46.4097 14.815L47.3324 8.96967C47.3507 8.85433 47.2614 8.75 47.145 8.75H46.0917C45.9987 8.75033 45.9187 8.81833 45.9044 8.91067Z'
          fill='#179BD7'
        />
        <path
          d='M8.56101 16.2185L8.73534 15.1111L8.34701 15.1021H6.49268L7.78134 6.93113C7.78534 6.90646 7.79834 6.88346 7.81734 6.86713C7.83634 6.8508 7.86068 6.8418 7.88601 6.8418H11.0127C12.0507 6.8418 12.767 7.0578 13.141 7.48413C13.3163 7.68413 13.428 7.89313 13.482 8.12313C13.5387 8.36446 13.5397 8.6528 13.4843 9.00446L13.4803 9.03013V9.25546L13.6557 9.3548C13.8033 9.43313 13.9207 9.5228 14.0107 9.62546C14.1607 9.79646 14.2577 10.0138 14.2987 10.2715C14.341 10.5365 14.327 10.8518 14.2577 11.2088C14.1777 11.6195 14.0483 11.9771 13.8737 12.2698C13.713 12.5395 13.5083 12.7631 13.2653 12.9365C13.0333 13.1011 12.7577 13.2261 12.446 13.3061C12.144 13.3848 11.7997 13.4245 11.422 13.4245H11.1787C11.0047 13.4245 10.8357 13.4871 10.703 13.5995C10.57 13.7141 10.482 13.8708 10.455 14.0421L10.4367 14.1418L10.1287 16.0935L10.1147 16.1651C10.111 16.1878 10.1047 16.1991 10.0953 16.2068C10.087 16.2138 10.075 16.2185 10.0633 16.2185H8.56101Z'
          fill='#253B80'
        />
        <path
          d='M13.822 9.05664C13.8127 9.11631 13.802 9.17731 13.79 9.23997C13.3777 11.357 11.967 12.0883 10.1654 12.0883H9.24804C9.0277 12.0883 8.84204 12.2483 8.8077 12.4656L8.33804 15.4443L8.20504 16.2886C8.1827 16.4313 8.2927 16.56 8.4367 16.56H10.0637C10.2564 16.56 10.42 16.42 10.4504 16.23L10.4664 16.1473L10.7727 14.2033L10.7924 14.0966C10.8224 13.906 10.9864 13.766 11.179 13.766H11.4224C12.9987 13.766 14.2327 13.126 14.5934 11.274C14.744 10.5003 14.666 9.85431 14.2674 9.39997C14.1467 9.26297 13.997 9.14931 13.822 9.05664Z'
          fill='#179BD7'
        />
        <path
          d='M13.3906 8.88433C13.3276 8.86599 13.2626 8.84933 13.196 8.83433C13.129 8.81966 13.0603 8.80666 12.9896 8.79533C12.7423 8.75533 12.4713 8.73633 12.181 8.73633H9.73028C9.66995 8.73633 9.61262 8.74999 9.56128 8.77466C9.44828 8.82899 9.36428 8.93599 9.34395 9.06699L8.82262 12.369L8.80762 12.4653C8.84195 12.248 9.02762 12.088 9.24795 12.088H10.1653C11.967 12.088 13.3776 11.3563 13.79 9.23966C13.8023 9.17699 13.8126 9.11599 13.822 9.05633C13.7176 9.00099 13.6046 8.95366 13.483 8.91333C13.453 8.90333 13.422 8.89366 13.3906 8.88433Z'
          fill='#222D65'
        />
        <path
          d='M9.34382 9.06633C9.36416 8.93533 9.44816 8.82833 9.56116 8.77433C9.61282 8.74967 9.66982 8.736 9.73016 8.736H12.1808C12.4712 8.736 12.7422 8.755 12.9895 8.795C13.0602 8.80633 13.1288 8.81933 13.1958 8.834C13.2625 8.849 13.3275 8.86567 13.3905 8.884C13.4218 8.89333 13.4528 8.903 13.4832 8.91267C13.6048 8.953 13.7178 9.00067 13.8222 9.05567C13.9448 8.27333 13.8212 7.74067 13.3982 7.25833C12.9318 6.72733 12.0902 6.5 11.0132 6.5H7.88649C7.66649 6.5 7.47882 6.66 7.44482 6.87767L6.14249 15.1327C6.11682 15.296 6.24282 15.4433 6.40749 15.4433H8.33782L8.82249 12.3683L9.34382 9.06633Z'
          fill='#253B80'
        />
      </g>
      <rect x='0.5' y='0.5' width='51' height='23' rx='3.5' stroke='#E4E2DA' />
      <defs>
        <clipPath id='clip0_1100_76205'>
          <rect width='42.7778' height='11' fill='white' transform='translate(4.61133 6.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
