'use client';

export default function Quote({ color = '#0A89FF' }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='18' viewBox='0 0 24 18' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.25 16.498C7.73528 16.498 9.75 14.4833 9.75 11.998C9.75 9.51277 7.73528 7.49805 5.25 7.49805C2.76472 7.49805 0.75 9.51277 0.75 11.998C0.75 14.4833 2.76472 16.498 5.25 16.498Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.75 12C0.75 6.20101 5.45101 1.5 11.25 1.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.25 16.498C19.7353 16.498 21.75 14.4833 21.75 11.998C21.75 9.51277 19.7353 7.49805 17.25 7.49805C14.7647 7.49805 12.75 9.51277 12.75 11.998C12.75 14.4833 14.7647 16.498 17.25 16.498Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.75 12C12.75 6.20101 17.451 1.5 23.25 1.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
