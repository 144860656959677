'use client';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const ButtonOnLightOutline = styled(Button)(({ theme, variant, disabled }) => ({
  color: theme.palette.colorTheme.contentPrimaryOnLight,
  border: `2px solid ${theme.palette.colorTheme.contentPrimaryOnLight}`,
  width: '100%',
  textTransform: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
  '&:focus': {
    cursor: 'pointer',
  },
  ...(disabled && {
    borderColor: theme.palette.colorTheme.contentDisabled,
    color: theme.palette.colorTheme.contentDisabled,
  }),
  ...(variant === 'pressed' && {
    backgroundColor: theme.palette.colorTheme.onLightPressed,
  }),
}));

export default ButtonOnLightOutline;
