'use client';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const ButtonBase = styled(Button)(({ theme, variant, size = 'large' }) => ({
  alignItems: 'center',
  textAlign: 'center',
  padding: '8px',
  height: size === 'large' ? '48px' : '32px',
  color: theme.palette.colorTheme.contentPrimaryOnDark,
  width: '100%',
  backgroundColor: theme.palette.colorTheme.containerPrimaryOnLight,
  borderRadius: '4px',
  boxSizing: 'border-box',
  textTransform: 'none',
  textDecoration: 'none',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.colorTheme.onDarkHovered,
  },
  '&:focus': {
    cursor: 'pointer',
    backgroundColor: theme.palette.colorTheme.onDarkFocused,
    border: '2px solid',
    borderColor: theme.palette.colorTheme.buttonFocusBorder,
  },
  ...(variant === 'disabled' && {
    backgroundColor: theme.palette.colorTheme.onDarkDisabled,
  }),
  ...(variant === 'pressed' && {
    backgroundColor: theme.palette.colorTheme.onDarkPressed,
  }),
}));

export default ButtonBase;
