'use client';

export default function ApplePayIcon() {
  return (
    <svg width='67' height='28' viewBox='0 0 67 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.5153 3.98097C11.7446 4.89296 10.5115 5.61227 9.27838 5.50951C9.12425 4.2764 9.72796 2.96622 10.4344 2.15699C11.2051 1.21931 12.5538 0.55138 13.6457 0.5C13.7741 1.78449 13.2732 3.04329 12.5153 3.98097ZM13.6328 5.75357C11.8474 5.65081 10.3188 6.76832 9.47106 6.76832C8.61045 6.76832 7.31311 5.80495 5.90017 5.83064C4.06335 5.85633 2.35498 6.89676 1.4173 8.55376C-0.509437 11.8677 0.916348 16.7745 2.77886 19.4719C3.69085 20.8078 4.78267 22.2721 6.2213 22.2207C7.58286 22.1694 8.12234 21.3344 9.76649 21.3344C11.4235 21.3344 11.8987 22.2207 13.3374 22.195C14.8274 22.1694 15.7651 20.8592 16.6771 19.5233C17.7175 18.0076 18.1414 16.5304 18.1671 16.4534C18.1414 16.4277 15.2898 15.3359 15.2641 12.0476C15.2384 9.29876 17.512 7.98858 17.6147 7.91151C16.3302 6.01047 14.3264 5.80495 13.6328 5.75357ZM23.9473 2.02854V22.0538H27.0557V15.2074H31.3588C35.2893 15.2074 38.051 12.51 38.051 8.60514C38.051 4.70028 35.3407 2.02854 31.4615 2.02854H23.9473ZM27.0557 4.64891H30.6395C33.3369 4.64891 34.8783 6.08753 34.8783 8.61798C34.8783 11.1484 33.3369 12.5999 30.6266 12.5999H27.0557V4.64891ZM43.7284 22.2079C45.6809 22.2079 47.492 21.2188 48.3141 19.6518H48.3783V22.0538H51.2556V12.0861C51.2556 9.196 48.9435 7.33349 45.3854 7.33349C42.0843 7.33349 39.6438 9.22169 39.5538 11.8164H42.354C42.5852 10.5833 43.7284 9.77402 45.2955 9.77402C47.1966 9.77402 48.2627 10.6603 48.2627 12.2916V13.3963L44.3835 13.6275C40.7741 13.8459 38.8217 15.323 38.8217 17.892C38.8217 20.4867 40.8383 22.2079 43.7284 22.2079ZM44.5634 19.8316C42.9064 19.8316 41.8531 19.0352 41.8531 17.8149C41.8531 16.5561 42.8678 15.824 44.8074 15.7084L48.2627 15.49V16.6204C48.2627 18.4957 46.6699 19.8316 44.5634 19.8316ZM55.0962 27.5C58.1276 27.5 59.5534 26.344 60.7993 22.8373L66.2584 7.52617H63.0986L59.4378 19.3563H59.3735L55.7127 7.52617H52.463L57.7294 22.1051L57.4468 22.9914C56.9715 24.4943 56.2008 25.0723 54.8264 25.0723C54.5824 25.0723 54.1071 25.0466 53.9144 25.0209V27.4229C54.0943 27.4743 54.865 27.5 55.0962 27.5Z'
        fill='black'
      />
    </svg>
  );
}
