'use client';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LeadingArrow from '../icons/leadingArrow';

export default function BackButtonLongArrow({ handleClick, testId }) {
  return (
    <Box
      data-testid={testId}
      onClick={() => handleClick()}
      onKeyPress={() => handleClick()}
      tabIndex={0}
      aria-label='Back Button'
      padding='6px 0px'
      sx={{
        cursor: 'pointer',
        transition: '.5s ease',
        '&:hover': {
          transition: '.5s ease',
          marginLeft: '-10px',
        },
        display: 'inline-block',
        borderRadius: '4px',
      }}
    >
      <Stack gap='12px' direction='row'>
        <LeadingArrow />
      </Stack>
    </Box>
  );
}
