'use client';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const ButtonBlock = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: theme.palette.colorTheme.backgroundSecondaryLight,
  border: '1px solid',
  borderColor: theme.palette.colorTheme.strokePrimaryOnLightLight,
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.colorTheme.backgroundSecondaryLight,
    opacity: '0.8',
  },
}));

export default ButtonBlock;
