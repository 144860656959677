'use client';

export default function ThumbsUp({ color = '#6A685D', width = '100%', height = '100%' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid='thumbsUpIcon'
    >
      <path
        d='M15.75 22.2012H4.5V55.9513H15.75V22.2012Z'
        stroke={color}
        strokeWidth='2.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.7524 52.1892C27.1316 52.1892 28.0587 56.7204 43.4655 57.6459C47.7912 57.9057 50.8955 58.1297 53.7765 54.4359C60.1035 46.3237 63.1425 27.0319 57.0057 27.0319H44.4702C41.709 27.0319 39.4702 24.7933 39.4702 22.0318V12.3068C39.4702 4.75684 28.4255 0.794576 28.4255 9.25684C28.4255 15.3258 26.113 19.4979 22.2498 23.1733C20.3601 24.9711 18.1506 25.9336 15.7524 26.4477'
        stroke={color}
        strokeWidth='2.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
