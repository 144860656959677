'use client';

export default function SocialFacebook({ height = '32', width = '32', color = '#F1EEE6' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.4844 4.66663L17.177 4.67288C14.0562 4.80174 12.6667 6.90641 12.6667 9.65329V10.8973L10.6667 10.8977C10.2985 10.8977 10 11.1962 10 11.5644V15.12L10.0107 15.2398C10.0672 15.5508 10.3394 15.7866 10.6667 15.7866L12.6667 15.7853V25.3422C12.6667 25.7104 12.9651 26.0088 13.3333 26.0088H17.7778L17.8976 25.9981C18.2086 25.9417 18.4444 25.6695 18.4444 25.3422L18.444 15.7853L21.2 15.7866C21.5419 15.7866 21.8283 15.528 21.8632 15.1879L22.2276 11.6324L22.2295 11.5163C22.2054 11.1745 21.92 10.8977 21.5644 10.8977L18.444 10.8973L18.4444 9.86663C18.4314 9.70893 18.4909 9.60728 18.5868 9.57026L18.6484 9.55587L21.3333 9.55552C21.7015 9.55552 22 9.25704 22 8.88885V5.33329C22 4.9651 21.7015 4.66663 21.3333 4.66663H17.4844ZM20.6667 5.99996V8.22129L18.6034 8.22325L18.4976 8.23094C17.6517 8.31546 17.0289 9.07775 17.1144 9.93296L17.1111 11.5644L17.1219 11.6842C17.1783 11.9952 17.4505 12.2311 17.7778 12.2311L20.8253 12.2306L20.5973 14.452L17.7778 14.4533L17.6579 14.464C17.3469 14.5205 17.1111 14.7927 17.1111 15.12L17.1107 24.6746H14V15.12L13.9893 15.0001C13.9328 14.6891 13.6606 14.4533 13.3333 14.4533L11.3333 14.452V12.2306L13.3333 12.2311C13.7015 12.2311 14 11.9326 14 11.5644V9.65329C14 7.47112 15.0126 5.99996 17.4844 5.99996H20.6667Z'
        fill={color}
      />
    </svg>
  );
}
