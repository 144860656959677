'use client';

import { useEffect } from 'react';
import Link from 'next/link';
import { Typography, Box } from '@mui/material';
import LOCATION_PATHS from '@lingo/adobe-analytics';
import { getNested } from 'utils';
import { replaceUrls } from 'utils/urls';
import Image from 'next/image';
import CookieConsent from './cookieConsent';
import { SocialInstagram, SocialFacebook, SocialYoutube, CeMark, UkCaMark } from '../icons';

function LinkSection({ linksData, gridCol, environment, columns = 1 }) {
  return (
    <Box
      gridColumn={gridCol}
      mt={{ xs: 5, lg: 8 }}
      mb={{ xs: columns > 1 ? -15 : 0, lg: 0, large: columns > 1 ? -15 : 0 }}
    >
      <Typography color='colorTheme.contentPrimaryOnDark' variant='h4' marginBottom={2}>
        {linksData.linkHeader}
      </Typography>
      <Box
        display='flex'
        width={{ large: '240px' }}
        maxWidth={{ large: '240px' }}
        height={{ lg: 1, xs: columns > 1 ? 5 / 10 : 1 }}
        maxHeight={{ lg: 1, xs: columns > 1 ? 5 / 10 : 1 }}
        flexDirection='column'
        flexWrap='wrap'
        rowGap={{
          xs: 2,
          sm: 2,
        }}
        columnGap={{
          xs: 2,
          sm: 3,
        }}
      >
        {linksData.links.map((link) => {
          const url = replaceUrls(link.url, environment);

          // If we are going to account page, we need to use a regular
          // anchor tag or else it would send auth0 incorrect returnTo
          // simply turning off prefetch did not fix this
          if (link.url.endsWith('/account')) {
            return (
              <a
                href={url}
                rel='noreferrer'
                target={link.newTab ? '_blank' : '_self'}
                key={link.title}
                data-analytics-action={link.title}
                data-analytics-location='footer'
              >
                <Typography color='colorTheme.contentPrimaryOnDark' variant='bodyXS'>
                  {link.title}
                </Typography>
              </a>
            );
          }

          return (
            <Link
              href={url}
              target={link.newTab ? '_blank' : '_self'}
              key={link.title}
              data-analytics-action={link.title}
              data-analytics-location='footer'
            >
              <Typography color='colorTheme.contentPrimaryOnDark' variant='bodyXS'>
                {link.title}
              </Typography>
            </Link>
          );
        })}
      </Box>
    </Box>
  );
}

function FooterContent({ data, environment }) {
  return (
    <Box
      data-testid='defaultFooter'
      display='grid'
      gridTemplateColumns='repeat(14, 1fr)'
      data-analytics-location={LOCATION_PATHS.footer}
      columnGap={{
        xs: 1,
        sm: 1,
        md: 3,
        lg: 6,
      }}
      pb={10.625}
      bgcolor='colorTheme.backgroundPrimaryDark'
    >
      {/* Abbott / Lingo logo */}
      <Box gridColumn={{ xs: '2/14', lg: '2/5' }} display='flex' alignItems='start' mt={8}>
        <Image
          alt='app image for abbott below abbott icon'
          src='/uk/icons/abbott-stacked-logo.svg'
          width='44'
          height='50'
        />
        <Box height={50} width='1px' mx={3.5} backgroundColor='colorTheme.contentPrimaryOnDark' />
        <Box mt={1.25}>
          <Image
            alt='app image for lingo spelled out'
            src='/uk/icons/lingo-logo.svg'
            width='81'
            height='34'
          />
        </Box>
      </Box>

      {/* Lingo Links Section */}
      <LinkSection
        linksData={data.topLinks.lingoLinks}
        gridCol={{ xs: '2/14', sm: '2/7', lg: '5/7', large: '5/8' }}
        environment={environment}
        columns={2}
      />

      {/* Help Links Section */}
      <Box gridColumn={{ xs: '2/14', lg: '7/10', large: '8/11' }}>
        <LinkSection linksData={data.topLinks.helpLinks} environment={environment} />
        <Box width={43} height='1px' my={3} backgroundColor='colorTheme.contentPrimaryOnDark' />
        <Box gridColumn={{ xs: '2/14', lg: '8/14' }} display='flex' flexDirection='column' rowGap={1}>
          <a href={`mailto:${data.supportEmail}`}>
            <Typography color='colorTheme.contentPrimaryOnDark' variant='bodyS'>
              {data.supportEmail}
            </Typography>
          </a>{' '}
          <a href={`tel:${data.phoneNumber}`}>
            <Typography color='colorTheme.contentPrimaryOnDark' variant='bodyS'>
              {data.phoneNumber}
            </Typography>
          </a>
          <Typography color='colorTheme.contentPrimaryOnDark' variant='bodyS'>
            {data.hoursOfOperation}
          </Typography>
          <Typography color='colorTheme.contentPrimaryOnDark' variant='bodyS'>
            {data.hoursOfOperationTwo}
          </Typography>
        </Box>
      </Box>

      {/* Press and Partners section */}
      <LinkSection
        linksData={data.topLinks.pressAndPartnersLinks}
        gridCol={{ xs: '2/14', sm: '2/7', lg: '10/13', large: '11/14' }}
        environment={environment}
      />

      {/* Divider */}
      <Box
        width={1}
        height='2px'
        mt={5}
        mb={2}
        backgroundColor='colorTheme.contentPrimaryOnDark'
        gridColumn='2/14'
      />

      {/* Social Links */}
      <Box mb={5} gridColumn='2/14' justifyContent='space-between' display='flex'>
        <Box display='flex' gap={3}>
          <Link
            href={data.socialLinks.facebook}
            aria-label='facebook'
            data-analytics-action='facebook'
            data-analytics-location='footer'
          >
            <SocialFacebook />
          </Link>

          <Link
            href={data.socialLinks.instagram}
            aria-label='instagram'
            data-analytics-action='instagram'
            data-analytics-location='footer'
          >
            <SocialInstagram />
          </Link>

          <Link
            href={data.socialLinks.youtube}
            aria-label='youtube'
            data-analytics-action='youtube'
            data-analytics-location='footer'
          >
            <SocialYoutube />
          </Link>
        </Box>

        <Box>
          <Link
            href={data.appleAppDownloadLink}
            aria-label='apple app store'
            data-analytics-action='apple app store'
            data-analytics-location='footer'
          >
            <Image alt='apple app store' src='/uk/icons/apple-app-store.svg' width='100' height='32' />
          </Link>
        </Box>
      </Box>

      {/* Legal */}
      <Box rowGap={2} gridColumn='2/14' display='grid'>
        <Typography color='colorTheme.contentPrimaryOnDark' variant='bodyS'>
          {data.legalInfoOne}
        </Typography>
        <Typography color='colorTheme.contentPrimaryOnDark' variant='bodyS'>
          {data.legalInfoTwo}
        </Typography>
        <Typography color='colorTheme.contentPrimaryOnDark' variant='bodyS'>
          {data.legalInfoThree}
        </Typography>
        <Typography color='colorTheme.contentPrimaryOnDark' variant='bodyS'>
          {data.legalInfoFour}
        </Typography>
        <Box display='flex' alignItems='center'>
          <CeMark />
          <Typography marginLeft={{ xs: 1, sm: 1 }} color='colorTheme.contentPrimaryOnDark' variant='bodyS'>
            {data.legalInfoFive}
          </Typography>
        </Box>
        <Box display='flex' alignItems='center'>
          <UkCaMark />
          <Typography marginLeft={{ xs: 1, sm: 1 }} color='colorTheme.contentPrimaryOnDark' variant='bodyS'>
            {data.legalInfoSix}
          </Typography>
        </Box>
      </Box>

      <Box
        gridColumn={{ xs: '2/14', sm: '4/12' }}
        display='flex'
        flexDirection={{ xs: 'column', sm: 'row' }}
        columnGap={{ xs: 2, sm: 2 }}
        rowGap={{ xs: 0, sm: 2 }}
        my={5}
        flexWrap='wrap'
        justifyContent='center'
      >
        {data.footerDocs.docLinks.map((doc) => {
          const url = replaceUrls(doc.url, environment);
          return (
            <Link
              href={url}
              target='_blank'
              key={doc.title}
              data-analytics-action={doc.title}
              data-analytics-location='footer'
            >
              <Typography color='colorTheme.contentPrimaryOnDark' variant='bodyS'>
                {doc.title}
              </Typography>
            </Link>
          );
        })}
        <CookieConsent />
      </Box>
    </Box>
  );
}

export default function DefaultFooter({ environment, data }) {
  useEffect(() => {
    if (getNested(window, 'truste', 'eu')) {
      window.truste.eu.init();
    }
  });
  return <FooterContent data={data} environment={environment} />;
}
