'use client';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircleCheckmark from '../icons/circleCheckmark';

export default function BlockSuccess({ successText }) {
  return (
    <Stack
      width='100%'
      padding='24px'
      backgroundColor='colorTheme.backgroundSecondaryLight'
      gap='20px'
      alignItems='center'
      boxSizing='border-box'
      border='1px solid'
      borderRadius='4px'
      borderColor='colorTheme.successDark'
    >
      <CircleCheckmark />
      <Typography color='colorTheme.contentPrimaryOnLight'>{successText}</Typography>
    </Stack>
  );
}
