'use client';

export default function CircleCheck({ color = '#002A3A', width = '24px', height = '24px' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.66675 13.088L8.84453 16.1787C8.92713 16.3021 9.03801 16.4039 9.16795 16.4758C9.29788 16.5477 9.4431 16.5875 9.59152 16.592C9.73995 16.5964 9.88729 16.5653 10.0213 16.5013C10.1553 16.4373 10.272 16.3422 10.3619 16.224L17.3334 7.40356'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
