'use client';

export default function AbbottSymbolAndName() {
  return (
    <svg width='91' height='22' viewBox='0 0 91 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M24.8887 0H0.500103V3.66474H23.6712V3.66805C24.342 3.66805 24.8776 4.19401 24.8776 4.84698V17.1533L24.8745 17.1637C24.8704 17.8138 24.3294 18.3362 23.6658 18.3362H5.45648C4.78984 18.3362 4.24424 17.8096 4.24424 17.1532V12.1816C4.24424 11.5287 4.78984 10.9986 5.45648 10.9986H21.1227V7.33875H4.23708C2.17314 7.33875 0.5 8.97175 0.5 10.9884V18.3466C0.5 20.3646 2.17314 22 4.23708 22H24.8886C26.9544 22 28.6251 20.3646 28.6251 18.3466V3.65466C28.6251 1.63683 26.9544 8.07776e-05 24.8886 8.07776e-05'
        fill='white'
      />
      <path
        d='M38.7755 11.4185H41.312L40.0556 7.73768H40.0207L38.7755 11.4185ZM42.4703 5.19434L45.3921 13.74C45.7388 14.7827 45.9843 15.0239 46.5932 15.0239H47.1872V16.5283H40.8074V15.0239H41.3306C41.8551 15.0239 42.2913 14.9558 42.2913 14.77C42.2913 14.5944 42.2738 14.4066 42.2036 14.1496L41.8389 12.9216H38.2722L38.059 13.6057C37.8683 14.2024 37.7974 14.4558 37.7974 14.6958C37.7974 14.9717 38.4113 15.0239 38.8867 15.0239H39.3895V16.5283H34.25V15.0239H34.8453C35.4573 15.0239 35.6979 14.7827 36.0527 13.74L38.972 5.19434H42.4703Z'
        fill='white'
      />
      <path
        d='M51.1068 12.5249C51.1068 14.2717 51.5076 15.3125 52.4857 15.3125C53.4699 15.3125 53.8676 14.2717 53.8676 12.5249C53.8676 10.7829 53.4699 9.74088 52.4857 9.74088C51.5076 9.74088 51.1068 10.7829 51.1068 12.5249ZM48.2018 7.29778C48.2018 6.81482 48.08 6.5614 47.573 6.5614H46.7695V5.19434H51.1068V9.44867C51.7524 8.73048 52.5758 8.26932 53.3587 8.26932C55.9823 8.26932 56.9597 10.2731 56.9597 12.5249C56.9597 14.7827 55.9823 16.7817 53.3587 16.7817C52.2576 16.7817 51.2776 16.4062 50.668 15.4657L50.1629 16.5296H48.2018V7.29778Z'
        fill='white'
      />
      <path
        d='M60.9149 12.5249C60.9149 14.2717 61.3207 15.3125 62.2975 15.3125C63.2775 15.3125 63.6776 14.2717 63.6776 12.5249C63.6776 10.7829 63.2774 9.74088 62.2975 9.74088C61.3207 9.74088 60.9149 10.7829 60.9149 12.5249ZM58.0111 7.29778C58.0111 6.81482 57.888 6.5614 57.386 6.5614H56.5801V5.19434H60.9149V9.44867C61.5637 8.73048 62.3839 8.26932 63.1705 8.26932C65.794 8.26932 66.774 10.2731 66.774 12.5249C66.774 14.7827 65.794 16.7817 63.1705 16.7817C62.0694 16.7817 61.0895 16.4062 60.4787 15.4657L59.9698 16.5296H58.0111V7.29778Z'
        fill='white'
      />
      <path
        d='M70.707 12.5257C70.707 14.5423 71.0736 15.4148 72.1232 15.4148C73.1708 15.4148 73.5368 14.5423 73.5368 12.5257C73.5368 10.5091 73.1708 9.6378 72.1232 9.6378C71.0736 9.6378 70.707 10.5091 70.707 12.5257ZM76.6338 12.5257C76.6338 15.2622 74.7268 16.7819 72.1232 16.7819C69.5139 16.7819 67.6113 15.2622 67.6113 12.5257C67.6113 9.79091 69.514 8.26953 72.1232 8.26953C74.7268 8.26953 76.6338 9.79091 76.6338 12.5257Z'
        fill='white'
      />
      <path
        d='M89.0988 12.6632V13.9962C89.0988 14.7818 88.9254 15.2467 88.2606 15.2467C87.8591 15.2467 87.6683 14.9539 87.6683 14.5936V9.89327H89.8146V8.525H87.6684V5.34961H85.9342C85.7925 6.76594 85.3396 8.52492 83.5916 8.52492H80.865V5.34961H79.1356C78.9945 6.76594 78.5391 8.52492 76.793 8.52492V9.89319H77.9636V14.1833C77.9636 15.9277 78.9598 16.7808 80.7574 16.7808C82.8602 16.7808 83.6942 15.8603 83.6942 14.1833V12.6631H82.3002V13.9962C82.3002 14.7818 82.1237 15.2466 81.4614 15.2466C81.0556 15.2466 80.8649 14.9538 80.8649 14.5935V9.89319H84.7684V14.1833C84.7684 15.9277 85.7601 16.7808 87.5615 16.7808C89.6587 16.7808 90.5001 15.8603 90.5001 14.1833V12.6631H89.0988V12.6632Z'
        fill='white'
      />
    </svg>
  );
}
