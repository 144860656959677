'use client';

export default function CircleCheckmark({ height = '32', width = '32', color = '#5AB560' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 17.6321L11.2667 22.2681C11.3906 22.4532 11.5569 22.606 11.7518 22.7139C11.9467 22.8217 12.1645 22.8814 12.3872 22.8881C12.6098 22.8947 12.8308 22.8481 13.0318 22.7521C13.2328 22.6561 13.4079 22.5135 13.5427 22.3361L24 9.10547'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 16C1 19.9782 2.58035 23.7936 5.3934 26.6066C8.20644 29.4196 12.0218 31 16 31C19.9782 31 23.7936 29.4196 26.6066 26.6066C29.4196 23.7936 31 19.9782 31 16C31 12.0218 29.4196 8.20644 26.6066 5.3934C23.7936 2.58035 19.9782 1 16 1C12.0218 1 8.20644 2.58035 5.3934 5.3934C2.58035 8.20644 1 12.0218 1 16Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
