"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getEnvForHost;
var _environments = require("../environments");
// Shop Matchers
var LOCAL_SHOP_REGEX = /local-shop.hellolingo.com/;
var RESEARCH_SHOP_REGEX = /shop-r.hellolingo.com/;
var DEV_SHOP_REGEX = /shop-d.hellolingo.com/;
var QA_SHOP_REGEX = /shop-q.hellolingo.com/;
var STG_SHOP_REGEX = /shop-s.hellolingo.com/;

// Home Matchers
var LOCAL_HOME_REGEX = /local.hellolingo.com/;
var RESEARCH_HOME_REGEX = /research.hellolingo.com/;
var DEV_HOME_REGEX = /dev.hellolingo.com/;
var QA_HOME_REGEX = /qa.hellolingo.com/;
var STG_HOME_REGEX = /stg.hellolingo.com/;
function getEnvForHost(forwardedHost) {
  switch (true) {
    case LOCAL_HOME_REGEX.test(forwardedHost):
    case LOCAL_SHOP_REGEX.test(forwardedHost):
      return _environments.LOCAL;
    case DEV_HOME_REGEX.test(forwardedHost):
    case DEV_SHOP_REGEX.test(forwardedHost):
      return _environments.DEV;
    case RESEARCH_HOME_REGEX.test(forwardedHost):
    case RESEARCH_SHOP_REGEX.test(forwardedHost):
      return _environments.RESEARCH;
    case QA_HOME_REGEX.test(forwardedHost):
    case QA_SHOP_REGEX.test(forwardedHost):
      return _environments.QA;
    case STG_HOME_REGEX.test(forwardedHost):
    case STG_SHOP_REGEX.test(forwardedHost):
      return _environments.STG;
    default:
      return _environments.PROD;
  }
}