'use client';

export default function VisaCard({ width = 52, height = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 52 24'
      data-testid='visaCardIcon'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='51' height='23' rx='3.5' fill='white' />
      <path
        d='M32.1384 5.25C29.18 5.25 26.5362 6.78341 26.5362 9.6165C26.5362 12.8655 31.225 13.0899 31.225 14.7221C31.225 15.4094 30.4374 16.0246 29.0922 16.0246C27.1832 16.0246 25.7563 15.165 25.7563 15.165L25.1458 18.0239C25.1458 18.0239 26.7895 18.75 28.9718 18.75C32.2063 18.75 34.7514 17.1413 34.7514 14.2598C34.7514 10.8266 30.043 10.6089 30.043 9.09389C30.043 8.55552 30.6896 7.96563 32.0309 7.96563C33.5444 7.96563 34.7791 8.59081 34.7791 8.59081L35.3766 5.82959C35.3766 5.82959 34.0331 5.25 32.1384 5.25ZM5.07164 5.45839L5 5.87518C5 5.87518 6.24462 6.10297 7.36559 6.55734C8.80894 7.07838 8.91176 7.3817 9.15485 8.32381L11.8037 18.5351H15.3546L20.8249 5.45839H17.2822L13.7672 14.3493L12.3328 6.81295C12.2013 5.95042 11.535 5.45839 10.7194 5.45839H5.07164ZM22.2495 5.45839L19.4703 18.5351H22.8486L25.6179 5.45839H22.2495ZM41.0912 5.45839C40.2765 5.45839 39.8449 5.89453 39.5282 6.65666L34.5789 18.5351H38.1216L38.807 16.5554H43.123L43.5398 18.5351H46.6657L43.9387 5.45839H41.0912ZM41.5519 8.99132L42.602 13.8983H39.7887L41.5519 8.99132Z'
        fill='#1434CB'
      />
      <rect x='0.5' y='0.5' width='51' height='23' rx='3.5' stroke='#E4E2DA' />
    </svg>
  );
}
