'use client';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const NormalTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: '48px',
    width: '100%',
    boxSizing: 'border-box',
    border: '1px solid',
    color: theme.palette.colorTheme.contentPrimaryOnLight,
    borderColor: theme.palette.colorTheme.strokePrimaryOnLightLight,
    borderRadius: '4px',
    backgroundColor: 'white',
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-focused': {
      borderColor: '#719ECE', // default color to match payon textboxes
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: theme.palette.colorTheme.contentPrimaryOnLight,
    },
  },
  '& .Mui-error': {
    borderColor: theme.palette.colorTheme.errorDark,
    '&.MuiFormHelperText-root': {
      marginLeft: '0px',
      color: theme.palette.colorTheme.errorDark,
    },
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.colorTheme.containerSecondaryOnLight,
  },
}));

export default NormalTextField;
