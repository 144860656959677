'use client';

import { styled } from '@mui/material/styles';
import ButtonBase from './buttonBase';

const ButtonOnLight = styled(ButtonBase)(({ theme, variant, disabled }) => ({
  color: theme.palette.colorTheme.backgroundSecondaryLight,
  backgroundColor: theme.palette.colorTheme.containerPrimaryOnLight,
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.colorTheme.contentSecondaryOnLight,
  },
  '&:focus': {
    cursor: 'pointer',
    backgroundColor: theme.palette.colorTheme.contentSecondaryOnLight,
  },
  ...(disabled && {
    backgroundColor: theme.palette.colorTheme.containerDisabled,
    color: theme.palette.colorTheme.contentDisabled,
  }),
  ...(variant === 'pressed' && {
    backgroundColor: theme.palette.colorTheme.onLightPressed,
    border: '2px solid',
    borderColor: theme.palette.colorTheme.strokePrimaryOnLightDark,
  }),
}));

export default ButtonOnLight;
