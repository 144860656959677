'use client';

export default function MastercardCard({ width = 52, height = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 52 24'
      data-testid='masterCardIcon'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='51' height='23' rx='3.5' fill='white' />
      <g clipPath='url(#clip0_3904_11657)'>
        <path d='M22.375 4.92493H30.25V19.0749H22.375V4.92493Z' fill='#FF5F00' />
        <path
          d='M22.875 12.0001C22.875 9.12511 24.225 6.57512 26.3 4.92512C24.775 3.72512 22.85 3.00012 20.75 3.00012C15.775 3.00012 11.75 7.02511 11.75 12.0001C11.75 16.9751 15.775 21.0001 20.75 21.0001C22.85 21.0001 24.775 20.2751 26.3 19.0751C24.225 17.4501 22.875 14.8751 22.875 12.0001Z'
          fill='#EB001B'
        />
        <path
          d='M40.8749 12C40.8749 16.975 36.8499 21 31.8749 21C29.7749 21 27.8499 20.275 26.325 19.075C28.4249 17.425 29.7499 14.875 29.7499 12C29.7499 9.12498 28.3999 6.57499 26.325 4.925C27.8499 3.725 29.7749 3 31.8749 3C36.8499 3 40.8749 7.04999 40.8749 12Z'
          fill='#F79E1B'
        />
      </g>
      <rect x='0.5' y='0.5' width='51' height='23' rx='3.5' stroke='#E4E2DA' />
      <defs>
        <clipPath id='clip0_3904_11657'>
          <rect width='42' height='18' fill='white' transform='translate(5 3)' />
        </clipPath>
      </defs>
    </svg>
  );
}
