'use client';

import Typography from '@mui/material/Typography';
import { ButtonOnLightOutline } from '../../buttons';

export default function TextAndButtonVariant({ handleClose, title, subtitle, buttonText }) {
  return (
    <>
      <Typography variant='h1' color='colorTheme.contentPrimaryOnLight'>
        {title}
      </Typography>
      <Typography variant='bodyXS' marginTop='16px' color='colorTheme.contentPrimaryOnLight'>
        {subtitle}
      </Typography>
      <ButtonOnLightOutline
        sx={{ marginTop: '40px' }}
        onClick={() => handleClose()}
        data-testid='keepExploringButtonTwo'
      >
        <Typography variant='buttonL' color='colorTheme.contentPrimaryOnLight'>
          {buttonText}
        </Typography>
      </ButtonOnLightOutline>
    </>
  );
}
