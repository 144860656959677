'use client';

/* eslint-disable react/no-array-index-key */
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import NormalTextField from '../normalTextfield';
import { ButtonOnLight } from '../../buttons';
import theme from '../../theme/theme';
import getPhrases from '../../helpers/getPhrases';
import LinkTypography from '../linkTypography';

export default function TextfieldEntryVariant({
  title,
  subtitle,
  boldSubtitle = '',
  disclaimerText,
  buttonText,
  textfieldErrorMessage,
  setTextContent,
  inputLabel,
  agreementLinks,
  isError,
  isSubmitError,
  setIsError,
  onButtonCLick,
  styles = {},
}) {
  const privacyNoticeParts = getPhrases(disclaimerText, agreementLinks);
  return (
    <>
      <Typography
        variant={styles?.title?.variant ?? 'h1'}
        color='colorTheme.contentPrimaryOnLight'
        sx={{ ...styles?.title }}
      >
        {title}
      </Typography>
      <Typography
        variant={styles?.boldSubtitle?.variant ?? 'bodyM'}
        px={0}
        py={2}
        color='colorTheme.contentPrimaryOnLight'
      >
        {boldSubtitle}
      </Typography>
      <Typography
        variant={styles?.subtitle?.variant ?? 'bodyM'}
        py={2}
        px={0}
        color='colorTheme.contentPrimaryOnLight'
      >
        {subtitle}
      </Typography>
      <InputLabel
        style={{
          fontSize: '16px',
          color: theme.palette.colorTheme.contentPrimaryOnLight,
          paddingBottom: '8px',
        }}
      >
        {inputLabel}
      </InputLabel>
      <NormalTextField
        type='text'
        name='Email'
        onChange={(e) => {
          setTextContent(e.target.value);
          setIsError(false);
        }}
        error={isError}
      />
      {isError && (
        <Typography variant='bodyS' color='colorTheme.errorDark' mt={1}>
          {textfieldErrorMessage}
        </Typography>
      )}
      {isSubmitError && (
        <Typography variant='bodyS' color='colorTheme.errorDark' mt={1}>
          Unable to save email. Please try again later.
        </Typography>
      )}
      <LinkTypography
        variant={styles?.agreement?.variant ?? 'bodyXS'}
        color='colorTheme.contentSecondaryOnLightWeb'
        dataTestId='reviewSectionAgreement'
        parts={privacyNoticeParts}
        aemData={{ agreementLinks }}
        keyWord='parts'
        styles={styles?.agreementLink}
      />
      <ButtonOnLight
        sx={{ marginTop: 4, ...styles?.ctaBtn }}
        onClick={() => onButtonCLick()}
        data-testid='submitModalButton'
        disabled={isSubmitError}
        disableRipple
        className='bg-blue-900 disabled:bg-gray-100 text-white disabled:text-gray-600'
      >
        <Typography variant='buttonL'>{buttonText}</Typography>
      </ButtonOnLight>
    </>
  );
}
