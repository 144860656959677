'use client';

import { useEffect } from 'react';
import Box from '@mui/system/Box';

export default function BrightcoveVideo({
  videoData,
  autoplay = null,
  loop = null,
  videoStyle,
  controls = false,
  vertical = false,
}) {
  if (videoData == null) {
    return <div />;
  }

  const { dataAccount, dataPlayer, dataVideoIdLarge, scriptSrc } = videoData;

  let videoJsControlsClass = '';
  if (videoStyle === 'noControls') {
    videoJsControlsClass = 'no-controls';
  } else if (videoStyle === 'noControlsSmall') {
    videoJsControlsClass = 'no-controls-small';
  } else if (videoStyle === 'noScreenOpt') {
    videoJsControlsClass = 'no-screen-opt';
  }

  useEffect(() => {
    const largePlayerScript = document.createElement('script');
    largePlayerScript.src = scriptSrc;
    document.head.appendChild(largePlayerScript);

    return () => {
      largePlayerScript.remove();
    };
  }, []);

  return (
    <Box
      gridColumn='2/14'
      display={{ lg: 'flex' }}
      overflow='center'
      style={{ aspectRatio: vertical ? '9/16' : '16/9' }}
      alignItems='center'
      data-testid='brightCovePlayer'
      height={vertical ? 1 : 'auto'}
    >
      <video-js
        controls={controls}
        autoplay={autoplay}
        muted={autoplay}
        playsinline={autoplay}
        data-account={dataAccount}
        data-player={dataPlayer}
        data-embed='default'
        controlBar={false}
        data-video-id={dataVideoIdLarge}
        data-playlist-id=''
        data-application-id=''
        loop={loop}
        aspectRatio={vertical ? '9:16' : '16:9'}
        class={`video-js ${vertical ? 'vjs-9-16' : 'vjs-16-9'} ${videoJsControlsClass}`}
        id='bright-cove-player'
        preload='auto'
      />
    </Box>
  );
}
