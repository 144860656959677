'use client';

export default function LightBulb({ color = '#FFFFFF', height = '32', width = '32' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14 30H18' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 15.9999C24.0149 11.8389 20.8375 8.36127 16.6921 8.00131C12.5467 7.64136 8.81744 10.5193 8.1149 14.6206C7.41236 18.7219 9.97111 22.677 14 23.7173V25.9999H18V23.7173C21.5257 22.8138 23.9935 19.6395 24 15.9999V15.9999Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M16 4V2' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M28 14H30' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2 16H4' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M24.4853 23.4854L25.9 24.9'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.10001 5.09998L7.51467 6.51464'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.4853 6.51464L25.9 5.09998'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.10001 24.9L7.51467 23.4854'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
