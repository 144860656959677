'use client';

export default function SingleNeutralActions({ height = '24', width = '24', color = '#002A3A' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.75 6.00001C6.75 7.39239 7.30312 8.72775 8.28769 9.71232C9.27226 10.6969 10.6076 11.25 12 11.25C13.3924 11.25 14.7278 10.6969 15.7123 9.71232C16.6969 8.72775 17.25 7.39239 17.25 6.00001C17.25 4.60762 16.6969 3.27226 15.7123 2.28769C14.7278 1.30312 13.3924 0.75 12 0.75C10.6076 0.75 9.27226 1.30312 8.28769 2.28769C7.30312 3.27226 6.75 4.60762 6.75 6.00001Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.25 23.25C2.25 20.6641 3.27723 18.1842 5.10571 16.3557C6.93419 14.5272 9.41415 13.5 12 13.5C14.5859 13.5 17.0658 14.5272 18.8943 16.3557C20.7228 18.1842 21.75 20.6641 21.75 23.25'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
