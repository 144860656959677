'use client';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

// TODO: need to remove and update places using ButtonLarge
const ButtonLarge = styled(Button)(({ variant }) => ({
  alignItems: 'center',
  textAlign: 'center',
  padding: '0 5px',
  height: '40px',
  color: 'white',
  backgroundColor: '#28393D',
  borderRadius: '4px',
  boxSizing: 'border-box',
  '&:hover': {
    cursor: 'pointer',
  },
  // error state, turn border red on error
  ...(variant === 'disabled' && {
    backgroundColor: '#868383',
  }),
}));

export default ButtonLarge;
