'use client';

import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Link from 'next/link';
import LOCATION_PATHS from '@lingo/adobe-analytics';
import { replaceUrls } from 'utils/urls';
import MenuIcon from '@mui/icons-material/Menu';
import { ButtonOnDark, ButtonOnLight } from '../buttons';
import LingoHeaderLogo from '../icons/lingoHeaderLogo';

export default function DefaultHeader({ cta, setShowMenu, hideMenu, environment, headerMode }) {
  const [lingoHomeUrl, setLingoHomeUrl] = useState('');

  const styles = {
    dark: {
      bgColor: 'colorTheme.backgroundPrimaryDark',
      color: '#F1EEE6',
      cta: { buttonOnDark: true },
      menu: { color: 'colorTheme.contentPrimaryOnDark' },
    },
  };
  useEffect(() => {
    const url = replaceUrls('https://www.hellolingo.com/', environment);
    setLingoHomeUrl(url);
  }, [environment]);

  return (
    <Box
      component='header'
      data-analytics-location={LOCATION_PATHS.header}
      alignContent='start'
      data-testid='defaultHeader'
      display='grid'
      gridTemplateColumns='repeat(14, 1fr)'
      gap={{
        xs: '0 8px',
        md: '0 24px',
        lg: '0 48px',
      }}
      zIndex='2'
      bgcolor={styles[headerMode]?.bgColor ?? 'colorTheme.backgroundPrimaryLightWeb'}
      justifyContent='space-between'
      alignItems='center'
      padding='24px 0'
      position='sticky'
      top={0}
    >
      <Box gridColumn='2/4'>
        <Link
          href={lingoHomeUrl}
          data-analytics-action='home'
          data-analytics-location='header'
          data-testid='headerLogo'
        >
          <Box
            fontSize='5px'
            width='0px'
            height='0px'
            overflow='hidden'
            color='colorTheme.contentPrimaryOnDark'
          >
            route back to homepage
          </Box>
          <LingoHeaderLogo
            width='79px'
            height='32px'
            alt='logo of lingo spelled out that routes to'
            color={styles[headerMode]?.color}
          />
        </Link>
      </Box>
      {cta && (
        <Box
          display={{ xs: 'none', md: 'block' }}
          gridColumn={
            !hideMenu
              ? {
                  xs: '10/13',
                  md: '11/13',
                }
              : {
                  xs: '11/14',
                  md: '12/14',
                }
          }
          data-testid='headerCTA'
        >
          <Link
            style={{ textDecoration: 'none' }}
            href={cta.url}
            data-analytics-action='shop'
            data-analytics-location='header'
          >
            {styles[headerMode]?.cta?.buttonOnDark ? (
              <ButtonOnDark>
                <Typography variant='buttonL'>{cta.text}</Typography>
              </ButtonOnDark>
            ) : (
              <ButtonOnLight>
                <Typography variant='buttonL'>{cta.text}</Typography>
              </ButtonOnLight>
            )}
          </Link>
        </Box>
      )}
      {!hideMenu && (
        <Box
          gridColumn='13/14'
          textAlign='right'
          sx={{ cursor: 'pointer' }}
          tabIndex={0}
          aria-label='Open Menu'
          onClick={() => setShowMenu(true)}
          onKeyDown={() => setShowMenu(true)}
          data-testid='headerMenuButton'
        >
          <MenuIcon sx={{ display: { xs: 'block', sm: 'none' } }} />
          <Typography
            variant='bodySBold'
            sx={{ display: { xs: 'none', sm: 'block' }, color: styles[headerMode]?.menu?.color }}
          >
            Menu
          </Typography>
        </Box>
      )}
    </Box>
  );
}
