'use client';

export default function Pin({ color = '#002A3A', width = '32', height = '32' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5 12.2C13.9853 12.2 16 10.1852 16 7.69995C16 5.21467 13.9853 3.19995 11.5 3.19995C9.01472 3.19995 7 5.21467 7 7.69995C7 10.1852 9.01472 12.2 11.5 12.2Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.125 6.19995C11.3321 6.19995 11.5 6.36784 11.5 6.57495'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.75 6.57495C10.75 6.36784 10.9179 6.19995 11.125 6.19995'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.125 6.94995C10.9179 6.94995 10.75 6.78206 10.75 6.57495'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.5 6.57495C11.5 6.78206 11.3321 6.94995 11.125 6.94995'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.5 12.2V21.2'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
