'use client';

export default function CheckMark({ color = '#002A3A', width = '14', height = '12' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 7.22312L3.45 10.7001C3.63855 10.9818 3.95158 11.155 4.29037 11.1651C4.62916 11.1752 4.95197 11.021 5.157 10.7511L13 0.828125'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
