export default function VideoPlay() {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Play'>
        <circle id='Ellipse 1054' cx='32' cy='32' r='32' fill='white' />
        <path
          id='Polygon 1'
          d='M41.8 33.7319C43.1334 32.9621 43.1334 31.0376 41.8 30.2678L28.6001 22.6467C27.2667 21.8769 25.6 22.8392 25.6 24.3788V39.6208C25.6 41.1604 27.2667 42.1227 28.6 41.3529L41.8 33.7319Z'
          fill='#666562'
        />
      </g>
    </svg>
  );
}
