'use client';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronLeft from '../icons/chevronLeft';

function buttonSwitch(size, buttonText) {
  switch (size) {
    case 'md':
      return (
        <>
          <ChevronLeft width='24' height='24' />
          <Typography variant='buttonM' color='colorTheme.contentPrimaryOnLight'>
            {buttonText}
          </Typography>
        </>
      );
    case 'sm':
    default:
      return (
        <>
          <ChevronLeft />
          <Typography variant='buttonS' color='colorTheme.contentPrimaryOnLight'>
            {buttonText}
          </Typography>
        </>
      );
  }
}

export default function BackButton({ handleClick, buttonText, size = 'sm' }) {
  return (
    <Box
      onClick={() => handleClick()}
      tabIndex={0}
      aria-label='Back Button'
      padding='6px 0px'
      sx={{
        cursor: 'pointer',
        transition: '.5s ease',
        '&:hover': {
          transition: '.5s ease',
          marginLeft: '-10px',
        },
        display: 'inline-block',
        borderRadius: '4px',
      }}
    >
      <Stack gap='12px' direction='row'>
        {buttonSwitch(size, buttonText)}
      </Stack>
    </Box>
  );
}
