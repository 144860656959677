'use client';

export default function VoiceIdSmartphone({ color = '#28393D', height = '30', width = '30' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.1 17.8125V25.3125C17.1 27.3836 15.4882 29.0625 13.5 29.0625H4.49999C2.51177 29.0625 0.899994 27.3836 0.899994 25.3125V4.6875C0.899994 2.61643 2.51177 0.9375 4.49999 0.9375H13.5'
        stroke={color}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.899994 23.4313H17.1'
        stroke={color}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.7 2.8125V15.9375'
        stroke={color}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.1 5.625V13.125'
        stroke={color}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.3 5.625V13.125'
        stroke={color}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5 7.5V11.25'
        stroke={color}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M27.9 7.5V11.25'
        stroke={color}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
