'use client';

import Typography from '@mui/material/Typography';
import Link from 'next/link';

export default function LinkTypography({ parts, aemData, variant, color, dataTestId, keyWord, styles = {} }) {
  const jsonLinks = aemData?.agreementLinks;
  const linkTexts = Object.keys(jsonLinks);

  return (
    <Typography variant={variant} color={color} data-testid={dataTestId} sx={{ ...styles }}>
      {parts?.map((part, index) => {
        if (linkTexts.includes(part)) {
          return (
            <Link
              // eslint-disable-next-line react/no-array-index-key
              key={`${keyWord}${index}`}
              href={jsonLinks[part]}
              target='_blank'
              style={{ textDecoration: 'underline', color: 'inherit' }}
            >
              {part}
            </Link>
          );
        }
        return part;
      })}
    </Typography>
  );
}
