const getPhrases = (fullText, jsonLinks) => {
  const myPhrases = Object.keys(jsonLinks);
  // go through keys
  let fullTextCopy = fullText;
  myPhrases.forEach((phrase) => {
    // search for all occurrences of phrase and wrap with link
    const regex = new RegExp(phrase, 'gi');
    fullTextCopy = fullTextCopy?.replace(regex, `<LINK>${phrase}<LINK>`);
  });
  // create an array by splitting the text
  return fullTextCopy?.split('<LINK>');
};

export default getPhrases;
