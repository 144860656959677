const LOCATION_PATHS = {
  header: 'header',
  hero: 'hero',
  footer: 'footer',
  signup: 'signup',
  faq: 'faq',
  experts: 'experts',
  stickyCTA: 'sticky-cta-shop',
  exploreArticles: 'blog-content',
  pages: {
    home: {
      intro: 'intro',
      topTestimonial: 'top-testimonial',
      highlights: 'highlights',
      value: 'value',
      process: 'process',
      howItWorks: 'how-it-works',
      mission: 'mission',
      insights: 'insights',
      results: 'results',
      testimonials: 'testimonials',
      pressLogos: 'press-logos',
      instagramFeed: 'instagram-feed',
      shop: 'shop',
      details: 'details',
      aboutus: 'aboutus',
    },
    landing: {
      section: 'sidekick',
      references: 'references',
      blogContent: 'blog-content',
    },
    benefitsQuiz: {
      quiz: 'quiz',
    },
    blog: {
      nav: 'blog-nav',
      essentialGuides: 'essential-guides',
      latestArticles: 'latest-articles',
      articleHightlight: 'article-highlight',
      article: 'article',
      subArticle: 'sub-article',
      references: 'references',
    },
    topic: {
      topic: 'topic',
      topicTag: 'topic-tag',
    },
    aboutUs: {
      about: 'about',
      story: 'story',
      biosensor: 'biosensor',
    },
    benefits: {
      drawer: 'sidekick',
    },
    biosensor: {
      biosensorDetails: 'biosensor-details',
      howItWorks: 'how-it-works',
      iconCards: 'icon-cards',
      abbott: 'abbott',
      shop: 'shop',
    },
    metabolism: {
      metabolism: 'metabolism',
      rate: 'rate',
      glucose: 'glucose',
      explore: 'explore',
      shop: 'shop',
      abbott: 'abbott',
    },
    science: {
      glucose: 'glucose',
      process: 'process',
      howItWorks: 'how-it-works',
      abbott: 'abbott',
      benefits: 'benefits',
    },
    pdp: {
      skuHighlight: 'sku-highlight',
      benefits: 'benefits',
    },
    guide: {
      header: 'header',
      toc: 'table-of-contents', // join now
    },
  },
};

export default LOCATION_PATHS;
