'use client';

export default function MyMembership({ color = '#002A3A', width = '24', height = '24' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.75 4H23.25V20.0714H0.75V4Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.8869 4.58936L14.1611 11.3008C13.5416 11.7774 12.7818 12.0358 12.0001 12.0358C11.2184 12.0358 10.4586 11.7774 9.839 11.3008L1.11328 4.58936'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
