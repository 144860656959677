'use client';

import React, { useState, useEffect, useRef } from 'react';
import { IconButton } from '@mui/material';
import { clsx } from 'clsx';

import TextTestimonial from '../text/TextTestimonial';

import ArrowRightLong from '../../icons/arrowRightLong';
import ArrowLeftLong from '../../icons/arrowLeftLong';

import styles from './TextCarousel.module.scss';

export default function TextCarousel({ data }) {
  const slider = useRef(null);
  const scrollingTimer = useRef(null);
  const slideRefs = data.map(() => React.createRef());
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [shownav, setShownav] = useState(data?.length > 0);

  const sliderClassNames = clsx(styles.slider, { [styles.slider_nav_hidden]: data?.length < 2 });
  const sliderNavClassNames = clsx(styles.slide_nav, 'hidden md:block');
  const indicatorClassNames = 'grow h-px bg-blue-900';
  const indicatorClassNamesInactive = 'grow h-px bg-blue-900/20';

  const slideTo = (direction) => {
    if (slider.current !== null) {
      let left;
      const { scrollLeft, clientWidth } = slider.current;
      switch (direction) {
        case 'next':
          left = scrollLeft + clientWidth;
          break;
        case 'prev':
        default:
          left = scrollLeft - clientWidth;
          break;
      }
      slider.current.scroll({
        left,
      });
    }
  };

  const updateNav = () => {
    if (scrollingTimer.current !== null) {
      clearTimeout(scrollingTimer.current);
    }
    requestAnimationFrame(() => {
      if (slider.current !== null) {
        const { scrollLeft, clientWidth } = slider.current;
        const computedStyles = window.getComputedStyle(slider.current);
        const columnGap = parseFloat(computedStyles.columnGap) || 0;
        const newIndex = Math.floor((scrollLeft + columnGap / 2) / (clientWidth + columnGap));
        setCurrentSlideIndex(newIndex);
      }
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (slider.current !== null) {
        const { clientWidth } = slider.current;
        const computedStyles = window.getComputedStyle(slider.current);
        const columnGap = parseFloat(computedStyles.columnGap) || 0;
        const totalSlidesWidth = data.reduce((acc, _, index) => {
          const slideElement = slideRefs[index].current;
          const slideWidth = slideElement ? slideElement.offsetWidth + columnGap : 0;
          return acc + slideWidth;
        }, 0);
        const shouldShowNav = totalSlidesWidth > clientWidth;
        if (shownav !== shouldShowNav) {
          setShownav(shouldShowNav);
        }
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data, slideRefs, shownav]);

  return (
    <div className={styles.carousel}>
      <div className={sliderClassNames} ref={slider} onScroll={updateNav}>
        {data.map((content, index) => (
          <div
            key={`text-slide-${String(index)})`}
            className={styles.slide}
            id={`slide-${index}`}
            ref={slideRefs[index]}
          >
            <TextTestimonial content={content} />
          </div>
        ))}
      </div>
      {shownav && (
        <>
          <div className={sliderNavClassNames}>
            <IconButton
              onClick={() => slideTo('prev')}
              disabled={currentSlideIndex === 0}
              className='hover:bg-transparent'
            >
              <ArrowLeftLong opacity={currentSlideIndex === 0 ? '0.5' : '1'} />
            </IconButton>
            <IconButton
              onClick={() => slideTo('next')}
              disabled={currentSlideIndex === data.length - 1}
              className='hover:bg-transparent'
            >
              <ArrowRightLong opacity={currentSlideIndex === data.length - 1 ? '0.5' : '1'} />
            </IconButton>
          </div>
          <div className={styles.slide_indicators}>
            {Array.from({ length: data.length }, (_el, index) => (
              <div
                key={`text-indicator-${String(index)})`}
                className={index === currentSlideIndex ? indicatorClassNames : indicatorClassNamesInactive}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
