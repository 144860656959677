'use client';

export default function ExclamationTriangle({ color, width = '33', height = '30' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 33 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.5116 9.66672H17.4838C17.6727 9.66672 17.8227 9.82228 17.8171 10.0112L17.4005 20.9001C17.3949 21.0778 17.2449 21.2223 17.0671 21.2223H15.9282C15.7505 21.2223 15.6005 21.0834 15.5949 20.9001L15.1782 10.0112C15.1727 9.82228 15.3227 9.66672 15.5116 9.66672ZM16.5005 22.3334C15.6393 22.3334 14.9449 23.0278 14.9449 23.8889C14.9449 24.7501 15.6393 25.4445 16.5005 25.4445C17.3616 25.4445 18.056 24.7501 18.056 23.8889C18.056 23.0278 17.3616 22.3334 16.5005 22.3334ZM32.1393 25.2223L18.8116 2.11117C17.7893 0.333388 15.2171 0.333388 14.1893 2.11117L0.86157 25.2223C-0.160652 26.9945 1.11713 29.2223 3.17268 29.2223H29.8338C31.8782 29.2223 33.1671 27.0001 32.1393 25.2223ZM29.8338 27.4445H3.16713C2.48379 27.4445 2.05601 26.7056 2.3949 26.1112L15.7282 3.00005C16.0671 2.41117 16.9282 2.40561 17.2671 3.00005L30.6005 26.1112C30.9449 26.7001 30.5171 27.4445 29.8338 27.4445Z'
        fill={color}
      />
    </svg>
  );
}
