'use client';

export default function FocusIcon({ color = '#0A89FF', width = '48', height = '32' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 48 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M30.9755 15.9565C30.9755 24.1477 24.3352 30.788 16.144 30.788C7.95279 30.788 1.3125 24.1477 1.3125 15.9565C1.3125 7.76529 7.95279 1.125 16.144 1.125C24.3352 1.125 30.9755 7.76529 30.9755 15.9565Z'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M46.6875 16C46.6875 24.2152 40.0277 30.875 31.8125 30.875C23.5973 30.875 16.9375 24.2152 16.9375 16C16.9375 7.78476 23.5973 1.125 31.8125 1.125C40.0277 1.125 46.6875 7.78476 46.6875 16Z'
        stroke={color}
        strokeWidth='1.5'
      />
    </svg>
  );
}
