'use client';

export default function GraphStatsAscend({ color = '#28393D', height = '32', width = '32' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M31 17V8H22' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M31 8L20.4147 18.5853C20.0396 18.9607 19.5307 19.1716 19 19.1716C18.4693 19.1716 17.9604 18.9607 17.5853 18.5853L13.4147 14.4147C13.0396 14.0393 12.5307 13.8284 12 13.8284C11.4693 13.8284 10.9604 14.0393 10.5853 14.4147L1 24'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
