'use client';

export default function QuestionCircle({ color = '#28393D' }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9 9.00011C9.00029 7.47454 10.1455 6.19212 11.6613 6.01987C13.1771 5.84762 14.5808 6.84039 14.9234 8.327C15.266 9.8136 14.4383 11.3206 13 11.8291C12.4004 12.0411 11.9997 12.6081 12 13.2441V14.2501'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 17.25C11.7929 17.25 11.625 17.4179 11.625 17.625C11.625 17.8321 11.7929 18 12 18C12.2071 18 12.375 17.8321 12.375 17.625C12.375 17.4179 12.2071 17.25 12 17.25V17.25'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z'
        stroke={color}
        strokeWidth='1.5'
      />
    </svg>
  );
}
