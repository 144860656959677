'use client';

export default function InstagramLogo() {
  return (
    <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M24.98 1H8.02C4.41911 1 1.5 3.91911 1.5 7.52V24.48C1.5 28.0809 4.41911 31 8.02 31H24.98C28.5809 31 31.5 28.0809 31.5 24.48V7.52C31.5 3.91911 28.5809 1 24.98 1Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5007 20.6663C19.078 20.6663 21.1673 18.577 21.1673 15.9997C21.1673 13.4223 19.078 11.333 16.5007 11.333C13.9233 11.333 11.834 13.4223 11.834 15.9997C11.834 18.577 13.9233 20.6663 16.5007 20.6663Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.834 5.33301H11.1673C8.2218 5.33301 5.83398 7.72082 5.83398 10.6663V21.333C5.83398 24.2785 8.2218 26.6663 11.1673 26.6663H21.834C24.7794 26.6663 27.1673 24.2785 27.1673 21.333V10.6663C27.1673 7.72082 24.7794 5.33301 21.834 5.33301Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.6797 10.1667C22.8638 10.1667 23.013 10.0174 23.013 9.83333C23.013 9.64924 22.8638 9.5 22.6797 9.5'
        stroke='white'
        strokeWidth='1.5'
      />
    </svg>
  );
}
