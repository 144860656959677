'use client';

export default function CeMark({ color = 'white', width = '18', height = '13' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 191 136' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M190.4 135.66V115.124C188.179 115.441 185.912 115.6 183.6 115.6C160.82 115.6 141.757 99.5973 137.088 78.2H176.8V57.8H137.088C141.757 36.4027 160.797 20.4 183.6 20.4C185.912 20.4 188.179 20.5587 190.4 20.876V0.34C188.156 0.113333 185.889 0 183.6 0C146.041 0 115.6 30.4413 115.6 68C115.6 105.559 146.041 136 183.6 136C185.889 136 188.156 135.887 190.4 135.66Z'
        fill={color}
      />
      <path
        d='M0 68C0 105.559 30.4413 136 68 136C70.448 136 72.8733 135.864 75.2533 135.615V115.056C72.896 115.419 70.4707 115.6 68 115.6C41.7067 115.6 20.4 94.2933 20.4 68C20.4 41.7067 41.7067 20.4 68 20.4C70.4707 20.4 72.896 20.5813 75.2533 20.944V0.385333C72.8733 0.135999 70.448 0 68 0C30.4413 0 0 30.4413 0 68Z'
        fill={color}
      />
    </svg>
  );
}
