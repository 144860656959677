'use client';

export default function LingoHeaderLogo({ height, width, color = '#28393D' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 68 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 14.7269V0.250639H4.05994V17.1992H12.6167V20.913H6.24199C2.79457 20.913 0 18.1435 0 14.7269ZM14.2224 2.44477C14.2224 1.08333 15.2844 0 16.6893 0C18.0941 0 19.1873 1.08333 19.1873 2.44477C19.1873 3.80622 18.0941 4.92036 16.6893 4.92036C15.2844 4.92036 14.2224 3.80622 14.2224 2.44477ZM18.6882 20.913H14.7845V6.61488H18.6882V20.913ZM23.585 6.61488L24.2202 8.37935C24.7512 7.79147 26.0939 6.30595 28.8733 6.30595C33.4634 6.30595 34.6507 9.36942 34.6507 12.8359V20.913H30.747V12.8359C30.747 10.979 29.9975 9.83406 28.249 9.83406C26.3753 9.83406 25.4696 11.0723 25.1259 12.0623V20.9138H21.2223V6.61488H23.5841H23.585ZM47.0725 21.0679V20.6657C46.4163 20.913 45.0425 21.2536 43.6057 21.2536C39.2962 21.2536 36.4546 17.9728 36.4546 13.7952C36.4546 9.61756 39.4214 6.30595 43.3562 6.30595C46.4163 6.30595 48.0715 8.4418 48.0715 8.4418L48.726 6.61821H50.9753V21.2727C50.9753 24.9882 47.9362 28 44.1872 28H39.6525V24.6076H47.0716V21.0679H47.0725ZM47.0725 11.8142C46.7599 11.0098 45.7609 9.83322 43.8872 9.83322C41.6388 9.83322 40.4835 11.7834 40.4835 13.7943C40.4835 15.8053 41.7018 17.7246 44.0746 17.7246C45.8861 17.7246 47.0725 16.9202 47.0725 16.9202V11.8134V11.8142ZM60.053 6.30512C64.1441 6.30512 67.4537 9.70915 67.4537 13.7327C67.4537 17.7563 64.1432 21.2536 60.053 21.2536C55.9629 21.2536 52.6835 17.8187 52.6835 13.7327C52.6835 9.6467 55.9939 6.30512 60.053 6.30512ZM60.053 17.7254C62.1452 17.7254 63.8315 15.9302 63.8315 13.7327C63.8315 11.5352 62.1452 9.83322 60.053 9.83322C57.9609 9.83322 56.3057 11.566 56.3057 13.7327C56.3057 15.8994 57.9609 17.7254 60.053 17.7254Z'
        fill={color}
      />
    </svg>
  );
}
