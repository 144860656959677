'use client';

export default function AngleUp() {
  return (
    <svg
      width='13'
      height='8'
      viewBox='0 0 13 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid='angleUp'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.39863 0.676567L11.8361 6.19844C12.0564 6.41875 12.0564 6.775 11.8361 6.99532L11.5033 7.32813C11.283 7.54844 10.9268 7.54844 10.7064 7.32813L6.00019 2.53282L1.29395 7.32344C1.07363 7.54375 0.717383 7.54375 0.49707 7.32344L0.164258 6.99063C-0.0560547 6.77032 -0.0560547 6.41407 0.164258 6.19375L5.60176 0.67188C5.82207 0.456255 6.17832 0.456255 6.39863 0.676567Z'
        fill='#28393D'
      />
    </svg>
  );
}
