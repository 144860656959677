'use client';

export default function ShippingHouse({ color = '#002A3A', height = '25', width = '24' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.272 24.247C22.8115 24.2454 23.2484 23.8085 23.25 23.269V10.747C23.2327 10.4416 23.0975 10.1548 22.873 9.94701L12 1.74701L1.127 9.94701C0.902463 10.1548 0.76731 10.4416 0.75 10.747V23.269C0.751647 23.8085 1.18855 24.2454 1.728 24.247H22.272Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
