'use client';

export default function EmailUnsubscribe({ color = '#002A3A' }) {
  return (
    <svg width='48' height='47' viewBox='0 0 48 47' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.5 45.1844C41.1274 45.1844 46.5 40.0505 46.5 33.7175C46.5 27.3844 41.1274 22.2505 34.5 22.2505C27.8726 22.2505 22.5 27.3844 22.5 33.7175C22.5 40.0505 27.8726 45.1844 34.5 45.1844Z'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.0161 41.8227L42.9841 25.6104'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 30.8508H4.5C2.84315 30.8508 1.5 29.5673 1.5 27.984V5.05009C1.5 3.46683 2.84315 2.18335 4.5 2.18335H40.5C42.1569 2.18335 43.5 3.46683 43.5 5.05009V17.9504'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M42.8222 3.23438L26.5342 15.2059C24.156 16.9541 20.8444 16.9541 18.4662 15.2059L2.17822 3.23438'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
