'use client';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

export function SingleDay({ number, testid }) {
  return (
    <Box
      backgroundColor='colorTheme.backgroundSecondaryLight'
      maxWidth={{ xs: '62px', md: '130px' }}
      width='100%'
      height={{ xs: '78px', md: '143px' }}
      alignItems='center'
      justifyContent='center'
      display='flex'
      position='relative'
      data-testid={testid}
    >
      <Divider
        sx={{
          bgcolor: 'colorTheme.strokePrimaryOnLightDark',
          borderBottomWidth: '1px',
          position: 'absolute',
          top: '50%',
          left: 0,
          right: 0,
          zIndex: '3',
        }}
      />
      <Typography color='colorTheme.contentPrimaryOnLight' variant='extraLarge'>
        {number}
      </Typography>
    </Box>
  );
}

export default function DayCountdown({ daysLeft, testid }) {
  const dayArray = daysLeft.split('');
  const numDays = dayArray.length;
  return (
    <Box
      width='100%'
      display='flex'
      justifyContent='center'
      gap={{ xs: '24px', md: '30px' }}
      data-testid={`${testid}Counter`}
    >
      <SingleDay number={numDays > 2 ? dayArray[dayArray.length - 3] : '0'} testid={`${testid}NumberOne`} />
      <SingleDay number={numDays > 1 ? dayArray[dayArray.length - 2] : '0'} testid={`${testid}NumberTwo`} />
      <SingleDay number={numDays > 0 ? dayArray[dayArray.length - 1] : '0'} testid={`${testid}NumberThree`} />
    </Box>
  );
}
