'use client';

export default function GooglePayLogo() {
  return (
    <svg width='63' height='24' viewBox='0 0 63 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M29.4511 11.6725V18.7485H27.1903V1.27484H33.1838C34.7028 1.27484 35.9981 1.77777 37.0578 2.78361C38.1411 3.78946 38.6828 5.01753 38.6828 6.46782C38.6828 7.9532 38.1411 9.18127 37.0578 10.1754C36.0098 11.1696 34.7146 11.6608 33.1838 11.6608H29.4511V11.6725ZM29.4511 3.42689V9.52045H33.2309C34.1258 9.52045 34.8794 9.21635 35.4682 8.61986C36.0687 8.02337 36.3749 7.29823 36.3749 6.47951C36.3749 5.6725 36.0687 4.95905 35.4682 4.36256C34.8794 3.74268 34.1376 3.43858 33.2309 3.43858H29.4511V3.42689Z'
        fill='#5F6368'
      />
      <path
        d='M44.5939 6.39764C46.266 6.39764 47.5848 6.84209 48.5503 7.73098C49.5159 8.61986 49.9987 9.83624 49.9987 11.3801V18.7485H47.8438V17.0877H47.7496C46.8194 18.4561 45.5712 19.1345 44.0169 19.1345C42.6864 19.1345 41.5795 18.7485 40.6846 17.9649C39.7897 17.1813 39.3422 16.2105 39.3422 15.0409C39.3422 13.8011 39.8132 12.8187 40.7552 12.0935C41.6972 11.3567 42.9572 10.9941 44.5233 10.9941C45.8656 10.9941 46.9725 11.2397 47.8321 11.731V11.2164C47.8321 10.4327 47.5259 9.77776 46.9018 9.22805C46.2778 8.67834 45.5477 8.40934 44.7117 8.40934C43.4517 8.40934 42.4508 8.93565 41.7208 9.99998L39.7308 8.76021C40.8259 7.18127 42.4509 6.39764 44.5939 6.39764ZM41.6737 15.076C41.6737 15.6608 41.921 16.152 42.4273 16.538C42.9219 16.9239 43.5106 17.1228 44.1818 17.1228C45.1356 17.1228 45.9834 16.7719 46.7252 16.0701C47.467 15.3684 47.8438 14.5497 47.8438 13.6023C47.1373 13.0526 46.16 12.7719 44.9001 12.7719C43.9816 12.7719 43.2162 12.9941 42.6039 13.4269C41.9798 13.883 41.6737 14.4327 41.6737 15.076Z'
        fill='#5F6368'
      />
      <path
        d='M62.2919 6.78363L54.7558 24H52.4244L55.2268 17.9766L50.2578 6.78363H52.7187L56.2984 15.3684H56.3455L59.8309 6.78363H62.2919Z'
        fill='#5F6368'
      />
      <path
        d='M20.4586 10.2456C20.4586 9.51343 20.3927 8.81284 20.2702 8.13916H10.7936V11.9988L16.2514 12C16.03 13.2842 15.3176 14.3789 14.2261 15.1087V17.6128H17.4748C19.3718 15.869 20.4586 13.2912 20.4586 10.2456Z'
        fill='#4285F4'
      />
      <path
        d='M14.2273 15.1088C13.3229 15.7146 12.1584 16.069 10.796 16.069C8.16427 16.069 5.93172 14.3076 5.13219 11.9333H1.78101V14.5158C3.44129 17.7883 6.85371 20.0339 10.796 20.0339C13.5208 20.0339 15.8098 19.1439 17.476 17.6117L14.2273 15.1088Z'
        fill='#34A853'
      />
      <path
        d='M4.81657 10.0175C4.81657 9.35087 4.92843 8.70643 5.13214 8.10058V5.51813H1.78096C1.09447 6.87134 0.708252 8.39883 0.708252 10.0175C0.708252 11.6362 1.09565 13.1637 1.78096 14.5169L5.13214 11.9345C4.92843 11.3286 4.81657 10.6842 4.81657 10.0175Z'
        fill='#FABB05'
      />
      <path
        d='M10.796 3.96491C12.2832 3.96491 13.615 4.47368 14.6665 5.46783L17.5455 2.61052C15.7969 0.992982 13.5172 0 10.796 0C6.85488 0 3.44129 2.24561 1.78101 5.51812L5.13219 8.10058C5.93172 5.72631 8.16427 3.96491 10.796 3.96491Z'
        fill='#E94235'
      />
    </svg>
  );
}
