'use client';

export default function Ellipse({ height = '20', width = '20', color = '#002A3A' }) {
  return (
    <svg
      color={color}
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='10' cy='10' r='9.5' stroke={color} />
    </svg>
  );
}
