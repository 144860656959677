'use client';

export default function Handshake({ color }) {
  return (
    <svg width='22' height='14' viewBox='0 0 22 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.5056 8.81335L13.5812 9.79113'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.74133 8.82226H5.91911L9.03911 12.3245C9.15228 12.4488 9.2893 12.549 9.44201 12.6193C9.59471 12.6895 9.75999 12.7283 9.928 12.7334C11.7444 11.426 13.4508 9.97226 15.0302 8.38671C15.128 8.11115 14.9413 7.8356 14.7724 7.60449L12.5413 4.72449'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.87446 2.49338L9.56334 2.22671C9.24377 1.9993 8.86222 1.87522 8.47001 1.87116C8.23248 1.87065 7.99708 1.91592 7.77668 2.00449L3.80334 3.58671'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.13672 2.06672H2.9145C3.13843 2.066 3.35438 2.14983 3.51917 2.30144C3.68397 2.45305 3.78547 2.66128 3.80339 2.88449V8.59116C3.78336 8.81278 3.68094 9.01883 3.51636 9.1686C3.35178 9.31836 3.13702 9.40095 2.9145 9.40005H1.13672'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.1368 9.40005H19.359C19.1365 9.40095 18.9217 9.31836 18.7571 9.1686C18.5925 9.01883 18.4901 8.81278 18.4701 8.59116V2.88449C18.488 2.66128 18.5895 2.45305 18.7543 2.30144C18.9191 2.14983 19.1351 2.066 19.359 2.06672H21.1368'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5814 4.37783L10.5147 5.39117C10.1765 5.50943 9.8062 5.49651 9.47703 5.35497C9.14786 5.21343 8.88377 4.95356 8.73693 4.62672C8.57874 4.28622 8.55891 3.8976 8.68161 3.54275C8.80431 3.18791 9.05997 2.89455 9.39471 2.7245L12.3814 1.22228C12.6731 1.07384 12.9963 0.99763 13.3236 1.00006C13.5659 1.00169 13.8062 1.04375 14.0347 1.1245L18.4792 2.74228'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
