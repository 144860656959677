'use client';

export default function ThickThumbsUp({ color, width = '100%', height = '100%' }) {
  return (
    <svg
      data-testid='thickThumbsUp'
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.58331 8.85083H2.83331V20.1009H6.58331V8.85083Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.58417 18.8469C10.3772 18.8469 10.6863 20.3574 15.8218 20.6659C17.2638 20.7524 18.2985 20.8271 19.2588 19.5959C21.3678 16.8918 22.3808 10.4612 20.3353 10.4612H16.1568C15.2363 10.4612 14.4901 9.71498 14.4901 8.79451V5.55284C14.4901 3.03617 10.8085 1.71542 10.8085 4.53617C10.8085 6.55916 10.0377 7.94986 8.74995 9.17498C8.12004 9.77426 7.38355 10.0951 6.58417 10.2664'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
