'use client';

export default function EnergyIcon({ color = '#0A89FF', width = '42', height = '42' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M40.5625 20.9582C40.5625 31.7377 31.8055 40.4789 21 40.4789C10.1945 40.4789 1.4375 31.7377 1.4375 20.9582C1.4375 10.1787 10.1945 1.4375 21 1.4375C31.8055 1.4375 40.5625 10.1787 40.5625 20.9582Z'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M33.5634 28.12C33.5634 34.9904 27.9819 40.5627 21.0935 40.5627C14.2051 40.5627 8.62363 34.9904 8.62363 28.12C8.62363 21.2495 14.2051 15.6772 21.0935 15.6772C27.9819 15.6772 33.5634 21.2495 33.5634 28.12Z'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M28.2755 33.3971C28.2755 37.3531 25.0614 40.5627 21.0935 40.5627C17.1256 40.5627 13.9116 37.3531 13.9116 33.3971C13.9116 29.4411 17.1256 26.2314 21.0935 26.2314C25.0614 26.2314 28.2755 29.4411 28.2755 33.3971Z'
        stroke={color}
        strokeWidth='1.5'
      />
    </svg>
  );
}
