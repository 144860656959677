'use client';

import { Stack, Typography } from '@mui/material';
import ExclamationTriangle from '../icons/exclamationTriangle';

export default function BlockError({ warningText }) {
  return (
    <Stack
      width='100%'
      padding='24px'
      backgroundColor='colorTheme.backgroundSecondaryLight'
      gap='20px'
      alignItems='center'
      boxSizing='border-box'
      border='1px solid'
      borderRadius='4px'
      borderColor='colorTheme.errorDark'
    >
      <ExclamationTriangle color='#EA012A' />
      <Typography color='colorTheme.contentPrimaryOnLight'>{warningText}</Typography>
    </Stack>
  );
}
