'use client';

export default function LeadingArrow() {
  return (
    <svg width='24' height='10' viewBox='0 0 24 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M23.25 5.49707H0.75'
        stroke='#002A3A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.5 9.24707L0.75 5.49707L4.5 1.74707'
        stroke='#002A3A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
