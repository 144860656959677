'use client';

import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { replaceUrls } from 'utils/urls';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { LingoHeaderLogo, QuestionCircle } from '../../icons';
import { ButtonOnLight } from '../../buttons';

export default function StickyNavBar({ menuData, cta, environment }) {
  const currentPathname = usePathname();
  const [lingoHomeUrl, setLingoHomeUrl] = useState('');
  const supportLinkUrl = 'https://support.hellolingo.com/hc/en-us';
  const linkPathRegex = /https:\/\/www\.hellolingo\.com(?<path>\/[a-z-]+)$/;

  useEffect(() => {
    const sanitizedLingoHomeUrl = replaceUrls('https://www.hellolingo.com/', environment);
    setLingoHomeUrl(sanitizedLingoHomeUrl);
  }, [environment]);

  const startPosition = 50;
  const maxScrollValue = 100;
  const opacityValue = `calc((((var(--scroll) - ${startPosition}) / ${maxScrollValue}) * 100) / 100)`;

  return (
    <Box
      display={{
        xs: 'none',
        md: 'grid',
      }}
      gridColumn='1/-1'
      gridTemplateColumns='repeat(14, 1fr)'
      rowGap={{
        md: '24px',
        lg: '48px',
      }}
      className='navbar'
      component='header'
      id='navbar'
      data-analytics-location='header'
      data-testid='defaultHeader'
      position='sticky'
      top={0}
      zIndex='3'
      paddingBottom='10px'
      style={{
        backgroundColor: `rgba(241, 238, 230, ${opacityValue})`,
      }}
    >
      <Box
        className='logo'
        gridColumn='2/3'
        style={{
          marginTop: '50px',
          paddingTop: '5px',
        }}
      >
        <Link
          href={lingoHomeUrl}
          data-analytics-action='home'
          data-analytics-location='header'
          data-testid='headerLogo'
        >
          <Box
            fontSize='5px'
            width='0px'
            height='0px'
            overflow='hidden'
            color='colorTheme.contentPrimaryOnDark'
          >
            route back to homepage
          </Box>
          <LingoHeaderLogo width='79px' height='32px' alt='logo of lingo spelled out that routes to' />
        </Link>
      </Box>
      <Stack
        className='menu'
        gridColumn='3/8'
        direction='row'
        gap={{
          lg: '24px',
          xl: '48px',
        }}
        alignItems='center'
        justify='center'
        marginTop='50px'
      >
        {menuData?.sectionLinksAndLabels.items.map((text, index) => {
          const url = replaceUrls(text.link, environment);
          const linkMatch = text.link.match(linkPathRegex);
          const isActive = linkMatch?.groups?.path === currentPathname;
          return (
            <Box
              key={text.title}
              sx={{
                cursor: 'pointer',
                borderBottom: 2,
                borderBottomColor: isActive ? 'black' : 'transparent',
                '&:hover': {
                  borderBottomColor: '#0A89FF',
                  transition: 'opacity 1.5s ease-in 0',
                },
                padding: '0px 2px 10px 2px',
              }}
            >
              <Link
                href={url}
                data-analytics-action={text.title}
                data-analytics-location='header'
                key={text.title}
              >
                <Typography variant='bodyS' color='black' data-testid={`menuLittleText${index}`}>
                  {text.title}
                </Typography>
              </Link>
            </Box>
          );
        })}
      </Stack>
      {cta && (
        <Box
          className='cta'
          data-testid='headerCTA'
          gridColumn='12/14'
          direction='row'
          marginTop='50px'
          textAlign='right'
        >
          <Link
            style={{ textDecoration: 'none' }}
            href={replaceUrls(cta.url ?? '', environment)}
            data-analytics-action={cta.text}
            data-analytics-location='header'
          >
            <ButtonOnLight sx={{ maxWidth: 156, display: 'inline-block' }}>
              <Typography variant='buttonL'>{cta.text}</Typography>
            </ButtonOnLight>
          </Link>
        </Box>
      )}
      <Box className='help' gridColumn='14/14' marginTop='60px' marginLeft='30px'>
        <Link
          href={replaceUrls(supportLinkUrl ?? '', environment)}
          data-analytics-action='home'
          data-analytics-location='header'
          data-testid='headerLogo'
        >
          <QuestionCircle />
        </Link>
      </Box>
    </Box>
  );
}
