'use client';

export default function PayPalLogo() {
  return (
    <svg width='105' height='28' viewBox='0 0 105 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3239_32185)'>
        <path
          d='M41.5589 6.02197H35.9634C35.5805 6.02197 35.2548 6.30015 35.1951 6.67815L32.932 21.0266C32.887 21.3097 33.1063 21.565 33.3935 21.565H36.0648C36.4477 21.565 36.7734 21.2868 36.8331 20.908L37.4435 17.038C37.5024 16.6592 37.8288 16.381 38.2109 16.381H39.9823C43.6682 16.381 45.7955 14.5973 46.351 11.0628C46.6014 9.51643 46.3617 8.30143 45.6376 7.45052C44.8423 6.51615 43.4317 6.02197 41.5589 6.02197ZM42.2045 11.2624C41.8985 13.2702 40.3644 13.2702 38.881 13.2702H38.0367L38.629 9.52052C38.6642 9.29388 38.8606 9.12697 39.0897 9.12697H39.4767C40.4871 9.12697 41.4403 9.12697 41.9328 9.70297C42.2266 10.0466 42.3166 10.5572 42.2045 11.2624Z'
          fill='#253B80'
        />
        <path
          d='M58.2849 11.1978H55.6054C55.3771 11.1978 55.1799 11.3647 55.1448 11.5913L55.0261 12.3408L54.8388 12.0692C54.2587 11.2273 52.9651 10.9458 51.674 10.9458C48.713 10.9458 46.184 13.1884 45.6915 16.3343C45.4354 17.9036 45.7995 19.4042 46.6897 20.4506C47.5062 21.4128 48.6746 21.8137 50.0647 21.8137C52.4505 21.8137 53.7735 20.2796 53.7735 20.2796L53.654 21.0242C53.609 21.3089 53.8283 21.5642 54.1138 21.5642H56.5275C56.9112 21.5642 57.2352 21.286 57.2958 20.9072L58.7439 11.7362C58.7898 11.4539 58.5713 11.1978 58.2849 11.1978ZM54.5499 16.4129C54.2914 17.9437 53.0764 18.9713 51.5268 18.9713C50.7487 18.9713 50.1268 18.7218 49.7276 18.2489C49.3316 17.7793 49.181 17.1108 49.307 16.3663C49.5484 14.8485 50.7839 13.7873 52.3098 13.7873C53.0707 13.7873 53.6892 14.0402 54.0967 14.5172C54.5049 14.9991 54.6669 15.6716 54.5499 16.4129Z'
          fill='#253B80'
        />
        <path
          d='M72.5557 11.1978H69.8631C69.6062 11.1978 69.3648 11.3254 69.2192 11.5389L65.5055 17.0093L63.9313 11.7525C63.8323 11.4236 63.5287 11.1978 63.1851 11.1978H60.5391C60.2176 11.1978 59.9942 11.5119 60.0965 11.8147L63.0624 20.5185L60.274 24.4548C60.0547 24.7648 60.2757 25.1911 60.6545 25.1911H63.3438C63.5991 25.1911 63.838 25.0668 63.9828 24.8573L72.9387 11.93C73.153 11.6208 72.9329 11.1978 72.5557 11.1978Z'
          fill='#253B80'
        />
        <path
          d='M81.4707 6.02197H75.8743C75.4922 6.02197 75.1666 6.30015 75.1069 6.67815L72.8438 21.0266C72.7988 21.3097 73.018 21.565 73.3036 21.565H76.1754C76.4421 21.565 76.6704 21.3702 76.7121 21.1052L77.3544 17.038C77.4133 16.6592 77.7398 16.381 78.1219 16.381H79.8924C83.5791 16.381 85.7056 14.5973 86.2619 11.0628C86.5131 9.51643 86.2718 8.30143 85.5477 7.45052C84.7532 6.51615 83.3435 6.02197 81.4707 6.02197ZM82.1162 11.2624C81.811 13.2702 80.2769 13.2702 78.7928 13.2702H77.9492L78.5424 9.52052C78.5776 9.29388 78.7723 9.12697 79.0022 9.12697H79.3892C80.3989 9.12697 81.3529 9.12697 81.8454 9.70297C82.1391 10.0466 82.2283 10.5572 82.1162 11.2624Z'
          fill='#179BD7'
        />
        <path
          d='M98.1959 11.1978H95.518C95.2881 11.1978 95.0925 11.3647 95.0581 11.5913L94.9395 12.3408L94.7513 12.0692C94.1712 11.2273 92.8785 10.9458 91.5874 10.9458C88.6264 10.9458 86.0982 13.1884 85.6057 16.3343C85.3504 17.9036 85.7129 19.4042 86.6031 20.4506C87.4212 21.4128 88.588 21.8137 89.9781 21.8137C92.3639 21.8137 93.6869 20.2796 93.6869 20.2796L93.5674 21.0242C93.5224 21.3089 93.7417 21.5642 94.0289 21.5642H96.4417C96.8238 21.5642 97.1494 21.286 97.2091 20.9072L98.6581 11.7362C98.7023 11.4539 98.483 11.1978 98.1959 11.1978ZM94.4609 16.4129C94.204 17.9437 92.9873 18.9713 91.4377 18.9713C90.6612 18.9713 90.0378 18.7218 89.6385 18.2489C89.2425 17.7793 89.0936 17.1108 89.218 16.3663C89.461 14.8485 90.6948 13.7873 92.2207 13.7873C92.9816 13.7873 93.6001 14.0402 94.0076 14.5172C94.4175 14.9991 94.5795 15.6716 94.4609 16.4129Z'
          fill='#179BD7'
        />
        <path
          d='M101.355 6.41536L99.0584 21.0264C99.0134 21.3095 99.2326 21.5648 99.5182 21.5648H101.827C102.211 21.5648 102.536 21.2866 102.595 20.9078L104.86 6.56018C104.905 6.27709 104.686 6.021 104.4 6.021H101.815C101.587 6.02181 101.39 6.18872 101.355 6.41536Z'
          fill='#179BD7'
        />
        <path
          d='M9.69486 24.3533L10.1228 21.6353L9.16959 21.6133H4.61804L7.78113 1.55716C7.79095 1.49662 7.82286 1.44016 7.8695 1.40007C7.91613 1.35998 7.97586 1.33789 8.03804 1.33789H15.7126C18.2604 1.33789 20.0187 1.86807 20.9367 2.91453C21.367 3.40544 21.6411 3.91844 21.7737 4.48298C21.9128 5.07534 21.9152 5.78307 21.7794 6.64625L21.7696 6.70925V7.26234L22.1999 7.50616C22.5624 7.69844 22.8504 7.91853 23.0713 8.17053C23.4395 8.59025 23.6776 9.12371 23.7782 9.75616C23.8821 10.4066 23.8478 11.1806 23.6776 12.0569C23.4812 13.0649 23.1638 13.9428 22.735 14.6612C22.3407 15.3231 21.8383 15.8721 21.2419 16.2975C20.6724 16.7017 19.9958 17.0085 19.2308 17.2049C18.4895 17.398 17.6443 17.4953 16.7173 17.4953H16.12C15.693 17.4953 15.2781 17.6492 14.9525 17.9249C14.626 18.2063 14.41 18.5909 14.3438 19.0114L14.2988 19.2561L13.5428 24.0465L13.5084 24.2224C13.4994 24.2781 13.4839 24.3059 13.4609 24.3247C13.4405 24.3419 13.411 24.3533 13.3824 24.3533H9.69486Z'
          fill='#253B80'
        />
        <path
          d='M22.6075 6.77295C22.5846 6.9194 22.5584 7.06913 22.529 7.22295C21.5169 12.4192 18.0543 14.2143 13.632 14.2143H11.3804C10.8396 14.2143 10.3839 14.607 10.2996 15.1405L9.14678 22.4518L8.82032 24.5242C8.76551 24.8744 9.03551 25.1902 9.38896 25.1902H13.3825C13.8554 25.1902 14.2571 24.8466 14.3316 24.3802L14.3709 24.1773L15.1228 19.4057L15.171 19.1439C15.2447 18.6759 15.6472 18.3322 16.1201 18.3322H16.7174C20.5866 18.3322 23.6155 16.7613 24.5008 12.2155C24.8706 10.3165 24.6791 8.73086 23.7006 7.61568C23.4044 7.2794 23.037 7.0004 22.6075 6.77295Z'
          fill='#179BD7'
        />
        <path
          d='M21.5486 6.35082C21.394 6.30582 21.2344 6.26491 21.0708 6.22809C20.9063 6.19209 20.7378 6.16019 20.5643 6.13237C19.9573 6.03419 19.2921 5.98755 18.5794 5.98755H12.5642C12.4161 5.98755 12.2753 6.02109 12.1493 6.08164C11.872 6.215 11.6658 6.47764 11.6159 6.79919L10.3363 14.9041L10.2994 15.1405C10.3837 14.6071 10.8394 14.2144 11.3803 14.2144H13.6319C18.0542 14.2144 21.5167 12.4185 22.5288 7.223C22.5591 7.06919 22.5844 6.91946 22.6073 6.773C22.3513 6.63719 22.0739 6.521 21.7753 6.422C21.7016 6.39746 21.6255 6.37373 21.5486 6.35082Z'
          fill='#222D65'
        />
        <path
          d='M11.616 6.79918C11.6659 6.47764 11.8721 6.215 12.1494 6.08245C12.2763 6.02191 12.4162 5.98836 12.5643 5.98836H18.5795C19.2922 5.98836 19.9574 6.035 20.5644 6.13318C20.7379 6.161 20.9064 6.19291 21.0709 6.22891C21.2345 6.26573 21.3941 6.30664 21.5487 6.35164C21.6256 6.37454 21.7017 6.39827 21.7762 6.422C22.0748 6.521 22.3522 6.638 22.6083 6.773C22.9093 4.85273 22.6058 3.54527 21.5675 2.36136C20.4229 1.058 18.357 0.5 15.7134 0.5H8.0389C7.4989 0.5 7.03826 0.892727 6.95481 1.427L3.75817 21.6893C3.69517 22.0902 4.00445 22.4518 4.40863 22.4518H9.14672L10.3364 14.9041L11.616 6.79918Z'
          fill='#253B80'
        />
      </g>
      <defs>
        <clipPath id='clip0_3239_32185'>
          <rect width='105' height='27' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
