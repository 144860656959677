export default function AppleStore({ width = 102, height = 34 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M93.3723 0.0001105H8.08351C7.77262 0.0001105 7.46546 0.0001105 7.1554 0.00181049C6.89584 0.00351049 6.63836 0.00844897 6.37631 0.0126055C5.80702 0.019322 5.23907 0.0695351 4.6774 0.162808C4.11652 0.258112 3.5732 0.437787 3.06583 0.695757C2.55909 0.955908 2.09606 1.29395 1.69354 1.69761C1.28891 2.10015 0.951625 2.56542 0.694645 3.07554C0.436957 3.58464 0.258274 4.13019 0.16477 4.69335C0.0703767 5.2558 0.0195828 5.82473 0.0128442 6.39505C0.00497659 6.65566 0.00414575 6.91713 0 7.17778V26.8248C0.00414575 27.0888 0.00497659 27.3444 0.0128442 27.6084C0.0195849 28.1787 0.0703788 28.7476 0.16477 29.3101C0.258016 29.8735 0.436709 30.4194 0.694645 30.9287C0.951508 31.4372 1.28884 31.9006 1.69354 32.3009C2.09453 32.7063 2.55786 33.0446 3.06583 33.3027C3.5732 33.5614 4.11645 33.7422 4.6774 33.8389C5.23917 33.9315 5.80706 33.9817 6.37631 33.9892C6.63836 33.995 6.89584 33.9983 7.1554 33.9983C7.46545 34 7.77264 34 8.08351 34H93.3723C93.677 34 93.9867 34 94.2914 33.9983C94.5497 33.9983 94.8146 33.995 95.0729 33.9892C95.6411 33.9821 96.2079 33.9318 96.7685 33.8389C97.3313 33.7415 97.8765 33.5608 98.3863 33.3027C98.8938 33.0444 99.3567 32.7062 99.7574 32.3009C100.161 31.899 100.499 31.4359 100.759 30.9287C101.015 30.419 101.192 29.8732 101.284 29.3101C101.379 28.7476 101.431 28.1788 101.441 27.6084C101.445 27.3444 101.445 27.0888 101.445 26.8248C101.451 26.516 101.451 26.2089 101.451 25.8951V8.10579C101.451 7.79452 101.451 7.48573 101.445 7.17778C101.445 6.91713 101.445 6.65566 101.441 6.39501C101.431 5.82465 101.379 5.25584 101.284 4.69332C101.192 4.13049 101.015 3.58498 100.759 3.0755C100.236 2.05302 99.406 1.22073 98.3863 0.69568C97.8765 0.438341 97.3312 0.258714 96.7685 0.162732C96.208 0.0690476 95.6411 0.018817 95.0729 0.0124865C94.8146 0.00833847 94.5497 0.00335749 94.2914 0.00169999C93.9867 0 93.677 0.0001105 93.3723 0.0001105Z'
        fill='#A6A6A6'
      />
      <path
        d='M7.15951 33.256C6.9012 33.256 6.64913 33.2527 6.39285 33.247C5.86194 33.24 5.33227 33.1937 4.80819 33.1083C4.31951 33.0239 3.84611 32.867 3.40361 32.6426C2.96516 32.4201 2.56526 32.1283 2.21923 31.7785C1.86819 31.4328 1.57593 31.0318 1.35404 30.5915C1.1297 30.1483 0.974447 29.6732 0.893683 29.1829C0.806465 28.656 0.759276 28.1232 0.752524 27.5891C0.747149 27.4099 0.740112 26.813 0.740112 26.813V7.17757C0.740112 7.17757 0.747607 6.58987 0.752567 6.41721C0.759032 5.884 0.805946 5.35204 0.892903 4.82596C0.973816 4.33425 1.12919 3.85783 1.35365 3.41318C1.57473 2.97315 1.86536 2.57188 2.21429 2.22493C2.56282 1.87468 2.964 1.58141 3.40319 1.35583C3.84468 1.13218 4.31718 0.976324 4.80488 0.893481C5.33068 0.807263 5.86219 0.760649 6.39493 0.754031L7.15992 0.743652H94.2872L95.0613 0.754447C95.5892 0.760737 96.1159 0.806935 96.6368 0.892648C97.1295 0.97653 97.6069 1.13347 98.0534 1.35832C98.9333 1.81294 99.6492 2.53219 100.101 3.41525C100.322 3.85683 100.475 4.32937 100.555 4.81683C100.643 5.34723 100.692 5.88336 100.702 6.42095C100.704 6.66167 100.704 6.92024 100.704 7.17757C100.711 7.49632 100.711 7.79971 100.711 8.10559V25.8949C100.711 26.2037 100.711 26.505 100.704 26.8088C100.704 27.0852 100.704 27.3385 100.701 27.5991C100.691 28.1271 100.643 28.6536 100.556 29.1745C100.477 29.6684 100.323 30.1472 100.098 30.594C99.875 31.0296 99.5844 31.4271 99.2374 31.7719C98.8911 32.1236 98.4904 32.4171 98.051 32.641C97.6057 32.8671 97.129 33.0246 96.6368 33.1083C96.1128 33.1941 95.5831 33.2405 95.0521 33.247C94.8038 33.2527 94.5438 33.256 94.2913 33.256L93.3723 33.2577L7.15951 33.256Z'
        fill='black'
      />
      <path
        d='M20.9989 17.2555C21.008 16.546 21.196 15.8504 21.5453 15.2333C21.8946 14.6163 22.3939 14.0978 22.9968 13.7262C22.6138 13.1778 22.1086 12.7265 21.5212 12.4082C20.9338 12.0898 20.2805 11.9131 19.6131 11.8922C18.1894 11.7424 16.8093 12.7463 16.0838 12.7463C15.3443 12.7463 14.2273 11.9071 13.0245 11.9319C12.2465 11.9571 11.4883 12.1839 10.8237 12.5903C10.1591 12.9966 9.61086 13.5686 9.23235 14.2506C7.5927 17.0967 8.81573 21.2796 10.3864 23.5802C11.1722 24.7067 12.0906 25.9651 13.2923 25.9205C14.4683 25.8716 14.9075 25.1687 16.3269 25.1687C17.7332 25.1687 18.1453 25.9205 19.3714 25.8921C20.6332 25.8716 21.4283 24.7606 22.1865 23.6234C22.7511 22.8207 23.1856 21.9335 23.4738 20.9947C22.7407 20.6839 22.1151 20.1635 21.675 19.4986C21.2349 18.8336 20.9998 18.0535 20.9989 17.2555Z'
        fill='white'
      />
      <path
        d='M18.683 10.3791C19.371 9.55105 19.7099 8.4867 19.6279 7.41211C18.5767 7.5228 17.6058 8.02647 16.9085 8.82277C16.5675 9.21179 16.3064 9.66436 16.14 10.1546C15.9737 10.6449 15.9053 11.1632 15.9388 11.6799C16.4646 11.6854 16.9847 11.5711 17.4601 11.3458C17.9354 11.1205 18.3535 10.79 18.683 10.3791Z'
        fill='white'
      />
      <path
        d='M35.8642 23.0685H31.8512L30.8875 25.9215H29.1877L32.9888 15.3662H34.7547L38.5558 25.9215H36.827L35.8642 23.0685ZM32.2668 21.752H35.4478L33.8797 17.1218H33.8358L32.2668 21.752Z'
        fill='white'
      />
      <path
        d='M46.7645 22.0742C46.7645 24.4657 45.4879 26.0021 43.5613 26.0021C43.0732 26.0277 42.5879 25.915 42.1607 25.6769C41.7336 25.4388 41.382 25.0849 41.1462 24.6557H41.1097V28.4674H39.5342V18.226H41.0593V19.506H41.0882C41.3349 19.0789 41.6924 18.7268 42.1228 18.4872C42.5532 18.2477 43.0403 18.1296 43.5323 18.1455C45.4804 18.1455 46.7645 19.6894 46.7645 22.0742ZM45.1451 22.0742C45.1451 20.5162 44.342 19.4918 43.1167 19.4918C41.9128 19.4918 41.1031 20.5377 41.1031 22.0742C41.1031 23.6248 41.9128 24.6632 43.1167 24.6632C44.342 24.6632 45.1451 23.6464 45.1451 22.0742Z'
        fill='white'
      />
      <path
        d='M55.2128 22.0742C55.2128 24.4657 53.9362 26.0021 52.0096 26.0021C51.5215 26.0277 51.0362 25.915 50.609 25.6769C50.1819 25.4388 49.8303 25.0849 49.5945 24.6557H49.558V28.4674H47.9825V18.226H49.5075V19.506H49.5365C49.7832 19.0789 50.1407 18.7268 50.5711 18.4872C51.0015 18.2477 51.4886 18.1296 51.9806 18.1455C53.9287 18.1455 55.2128 19.6894 55.2128 22.0742ZM53.5934 22.0742C53.5934 20.5162 52.7903 19.4918 51.565 19.4918C50.3611 19.4918 49.5514 20.5377 49.5514 22.0742C49.5514 23.6248 50.3611 24.6632 51.565 24.6632C52.7903 24.6632 53.5934 23.6464 53.5934 22.0742Z'
        fill='white'
      />
      <path
        d='M60.7966 22.9807C60.9133 24.0275 61.9275 24.7147 63.3135 24.7147C64.6415 24.7147 65.5969 24.0274 65.5969 23.0836C65.5969 22.2643 65.0206 21.7738 63.6562 21.4376L62.2918 21.108C60.3586 20.6399 59.4611 19.7334 59.4611 18.2625C59.4611 16.4414 61.0441 15.1904 63.2919 15.1904C65.5166 15.1904 67.0416 16.4414 67.093 18.2625H65.5025C65.4073 17.2092 64.5388 16.5733 63.2696 16.5733C62.0003 16.5733 61.1318 17.2167 61.1318 18.153C61.1318 18.8992 61.6866 19.3383 63.0435 19.6745L64.2035 19.96C66.3635 20.4722 67.261 21.3421 67.261 22.886C67.261 24.8608 65.6921 26.0976 63.1967 26.0976C60.8619 26.0976 59.2856 24.8898 59.1837 22.9807L60.7966 22.9807Z'
        fill='white'
      />
      <path
        d='M70.6612 16.4048V18.226H72.1208V19.4769H70.6612V23.7194C70.6612 24.3785 70.9535 24.6856 71.5951 24.6856C71.7684 24.6826 71.9414 24.6704 72.1134 24.6491V25.8925C71.8249 25.9466 71.5317 25.971 71.2383 25.9656C69.6843 25.9656 69.0782 25.3804 69.0782 23.8879V19.4769H67.9622V18.226H69.0782V16.4048H70.6612Z'
        fill='white'
      />
      <path
        d='M72.9662 22.0742C72.9662 19.6529 74.3886 18.1313 76.6066 18.1313C78.8321 18.1313 80.2478 19.6528 80.2478 22.0742C80.2478 24.5022 78.8395 26.0171 76.6066 26.0171C74.3745 26.0171 72.9662 24.5022 72.9662 22.0742ZM78.6425 22.0742C78.6425 20.4132 77.8833 19.4329 76.6066 19.4329C75.3299 19.4329 74.5715 20.4207 74.5715 22.0742C74.5715 23.7418 75.3299 24.7147 76.6066 24.7147C77.8833 24.7147 78.6425 23.7418 78.6425 22.0742Z'
        fill='white'
      />
      <path
        d='M81.5468 18.2259H83.0495V19.5358H83.0859C83.1876 19.1267 83.4266 18.7651 83.7628 18.5119C84.0991 18.2587 84.512 18.1293 84.9322 18.1454C85.1138 18.1447 85.2948 18.1645 85.472 18.2043V19.6819C85.2428 19.6116 85.0037 19.5794 84.7641 19.5864C84.5352 19.5771 84.307 19.6175 84.0952 19.705C83.8834 19.7925 83.6929 19.9248 83.537 20.093C83.381 20.2612 83.2632 20.4613 83.1916 20.6795C83.12 20.8976 83.0964 21.1287 83.1223 21.3569V25.9215H81.5468L81.5468 18.2259Z'
        fill='white'
      />
      <path
        d='M92.7362 23.6613C92.5243 25.0583 91.1673 26.0171 89.4311 26.0171C87.1982 26.0171 85.8123 24.5171 85.8123 22.1107C85.8123 19.6969 87.2057 18.1313 89.3649 18.1313C91.4885 18.1313 92.824 19.5939 92.824 21.9273V22.4685H87.4027V22.5639C87.3777 22.8472 87.4131 23.1325 87.5065 23.4009C87.5999 23.6694 87.7492 23.9149 87.9445 24.121C88.1398 24.3272 88.3766 24.4894 88.6392 24.5967C88.9018 24.7041 89.1842 24.7542 89.4676 24.7437C89.8399 24.7787 90.2135 24.6922 90.5329 24.4972C90.8523 24.3022 91.1003 24.009 91.2402 23.6613L92.7362 23.6613ZM87.4102 21.3645H91.2476C91.2618 21.1098 91.2233 20.855 91.1345 20.616C91.0458 20.3769 90.9087 20.1589 90.7319 19.9755C90.5551 19.7921 90.3424 19.6473 90.1072 19.5502C89.872 19.4531 89.6192 19.4058 89.3649 19.4113C89.1083 19.4098 88.854 19.4592 88.6166 19.5567C88.3792 19.6543 88.1634 19.798 87.9816 19.9796C87.7999 20.1612 87.6558 20.377 87.5577 20.6147C87.4597 20.8524 87.4095 21.1072 87.4102 21.3645Z'
        fill='white'
      />
      <path
        d='M32.069 7.42128C32.3993 7.39752 32.7308 7.44755 33.0395 7.56777C33.3482 7.68798 33.6264 7.87539 33.8541 8.11647C34.0818 8.35755 34.2533 8.64629 34.3562 8.96187C34.4592 9.27745 34.4909 9.612 34.4493 9.94139C34.4493 11.5617 33.5758 12.4931 32.069 12.4931H30.2418V7.42128H32.069ZM31.0275 11.7758H31.9812C32.2173 11.79 32.4535 11.7512 32.6727 11.6622C32.8919 11.5733 33.0885 11.4365 33.2483 11.2618C33.4081 11.0871 33.5271 10.8788 33.5965 10.6522C33.6659 10.4255 33.684 10.1863 33.6495 9.95172C33.6815 9.71812 33.6615 9.48031 33.591 9.25535C33.5206 9.03038 33.4014 8.82383 33.2419 8.65049C33.0825 8.47715 32.8868 8.34132 32.6688 8.25271C32.4509 8.16411 32.2161 8.12494 31.9812 8.13801H31.0275V11.7758Z'
        fill='white'
      />
      <path
        d='M35.3368 10.5777C35.3128 10.3262 35.3415 10.0724 35.421 9.83264C35.5006 9.59288 35.6292 9.37244 35.7987 9.18546C35.9683 8.99848 36.1749 8.84907 36.4054 8.74684C36.6358 8.64461 36.8851 8.5918 37.1371 8.5918C37.3891 8.5918 37.6384 8.64461 37.8688 8.74684C38.0993 8.84907 38.3059 8.99848 38.4754 9.18546C38.645 9.37244 38.7736 9.59288 38.8532 9.83264C38.9327 10.0724 38.9614 10.3262 38.9374 10.5777C38.9619 10.8295 38.9335 11.0836 38.8542 11.3238C38.7748 11.5639 38.6463 11.7848 38.4767 11.9721C38.3071 12.1595 38.1004 12.3092 37.8697 12.4116C37.639 12.5141 37.3894 12.567 37.1371 12.567C36.8848 12.567 36.6352 12.5141 36.4045 12.4116C36.1738 12.3092 35.9671 12.1595 35.7975 11.9721C35.6279 11.7848 35.4994 11.5639 35.42 11.3238C35.3407 11.0836 35.3123 10.8295 35.3368 10.5777ZM38.1625 10.5777C38.1625 9.74805 37.7907 9.26288 37.1383 9.26288C36.4834 9.26288 36.115 9.74806 36.115 10.5777C36.115 11.414 36.4835 11.8955 37.1383 11.8955C37.7908 11.8955 38.1625 11.4107 38.1625 10.5777Z'
        fill='white'
      />
      <path
        d='M43.7239 12.493H42.9424L42.1534 9.67407H42.0938L41.308 12.493H40.5339L39.4816 8.66553H40.2458L40.9297 11.5861H40.986L41.7709 8.66553H42.4936L43.2785 11.5861H43.3381L44.0187 8.66553H44.7721L43.7239 12.493Z'
        fill='white'
      />
      <path
        d='M45.6571 8.66554H46.3824V9.27357H46.4387C46.5342 9.05519 46.6953 8.87212 46.8995 8.74989C47.1037 8.62765 47.3409 8.57234 47.5779 8.59166C47.7637 8.57766 47.9502 8.60574 48.1236 8.67382C48.2971 8.7419 48.453 8.84824 48.5799 8.98495C48.7068 9.12167 48.8014 9.28526 48.8567 9.46361C48.912 9.64196 48.9265 9.83049 48.8993 10.0152V12.493H48.1459V10.2049C48.1459 9.58984 47.8793 9.28395 47.3221 9.28395C47.196 9.27806 47.0701 9.29958 46.953 9.34703C46.836 9.39449 46.7305 9.46676 46.644 9.55889C46.5574 9.65101 46.4917 9.76082 46.4514 9.88078C46.4111 10.0007 46.3972 10.128 46.4105 10.2539V12.493H45.6571L45.6571 8.66554Z'
        fill='white'
      />
      <path d='M50.0996 7.17139H50.853V12.493H50.0996V7.17139Z' fill='white' />
      <path
        d='M51.9005 10.5778C51.8765 10.3262 51.9053 10.0724 51.9848 9.83267C52.0644 9.59291 52.1931 9.37246 52.3626 9.18547C52.5322 8.99848 52.7388 8.84908 52.9693 8.74684C53.1998 8.64461 53.449 8.5918 53.7011 8.5918C53.9531 8.5918 54.2024 8.64461 54.4329 8.74684C54.6634 8.84908 54.87 8.99848 55.0395 9.18547C55.209 9.37246 55.3377 9.59291 55.4173 9.83267C55.4969 10.0724 55.5256 10.3262 55.5016 10.5778C55.526 10.8296 55.4976 11.0837 55.4183 11.3239C55.3389 11.564 55.2103 11.7848 55.0407 11.9722C54.8712 12.1595 54.6644 12.3092 54.4337 12.4117C54.2029 12.5141 53.9534 12.5671 53.7011 12.5671C53.4487 12.5671 53.1992 12.5141 52.9685 12.4117C52.7378 12.3092 52.531 12.1595 52.3614 11.9722C52.1918 11.7848 52.0632 11.564 51.9839 11.3239C51.9045 11.0837 51.8761 10.8296 51.9005 10.5778ZM54.7262 10.5778C54.7262 9.74813 54.3545 9.26295 53.7021 9.26295C53.0472 9.26295 52.6788 9.74813 52.6788 10.5778C52.6788 11.4141 53.0472 11.8955 53.7021 11.8955C54.3545 11.8955 54.7262 11.4108 54.7262 10.5778Z'
        fill='white'
      />
      <path
        d='M56.2946 11.4107C56.2946 10.7218 56.8063 10.3246 57.7145 10.2681L58.7486 10.2084V9.878C58.7486 9.47375 58.482 9.24548 57.967 9.24548C57.5465 9.24548 57.255 9.40029 57.1714 9.67089H56.442C56.519 9.01347 57.1358 8.5918 58.0018 8.5918C58.9589 8.5918 59.4987 9.0695 59.4987 9.878V12.4931H58.7734V11.9553H58.7138C58.5928 12.1482 58.4229 12.3055 58.2215 12.4111C58.02 12.5167 57.7942 12.5667 57.5671 12.5562C57.4069 12.5729 57.2449 12.5558 57.0917 12.5059C56.9384 12.456 56.7973 12.3745 56.6774 12.2665C56.5575 12.1586 56.4615 12.0267 56.3956 11.8793C56.3296 11.7319 56.2952 11.5723 56.2946 11.4107ZM58.7486 11.0837V10.7637L57.8164 10.8234C57.2906 10.8587 57.0522 11.038 57.0522 11.3754C57.0522 11.7199 57.3502 11.9204 57.7601 11.9204C57.8802 11.9326 58.0015 11.9204 58.1168 11.8846C58.2321 11.8489 58.339 11.7902 58.4313 11.7121C58.5235 11.6341 58.5991 11.5382 58.6536 11.4302C58.7081 11.3223 58.7404 11.2044 58.7486 11.0837Z'
        fill='white'
      />
      <path
        d='M60.489 10.5776C60.489 9.3682 61.1091 8.60203 62.0736 8.60203C62.3122 8.59101 62.549 8.6483 62.7563 8.76721C62.9636 8.88612 63.1329 9.06174 63.2443 9.27353H63.3006V7.17139H64.054V12.493H63.3321V11.8883H63.2725C63.1524 12.0987 62.9772 12.2721 62.7659 12.3898C62.5546 12.5075 62.3152 12.565 62.0736 12.5561C61.1025 12.5561 60.489 11.7899 60.489 10.5776ZM61.2672 10.5776C61.2672 11.3894 61.6489 11.8779 62.2872 11.8779C62.9223 11.8779 63.3147 11.3824 63.3147 10.5809C63.3147 9.78323 62.9181 9.28062 62.2872 9.28062C61.653 9.28062 61.2672 9.77244 61.2672 10.5776Z'
        fill='white'
      />
      <path
        d='M67.1712 10.5777C67.1472 10.3262 67.1759 10.0724 67.2555 9.83264C67.335 9.59288 67.4637 9.37244 67.6332 9.18546C67.8027 8.99848 68.0093 8.84907 68.2398 8.74684C68.4703 8.64461 68.7195 8.5918 68.9716 8.5918C69.2236 8.5918 69.4728 8.64461 69.7033 8.74684C69.9338 8.84907 70.1404 8.99848 70.3099 9.18546C70.4794 9.37244 70.6081 9.59288 70.6876 9.83264C70.7672 10.0724 70.7959 10.3262 70.7719 10.5777C70.7964 10.8295 70.768 11.0836 70.6887 11.3238C70.6093 11.5639 70.4807 11.7848 70.3112 11.9721C70.1416 12.1595 69.9348 12.3092 69.7041 12.4116C69.4734 12.5141 69.2239 12.567 68.9716 12.567C68.7192 12.567 68.4697 12.5141 68.239 12.4116C68.0083 12.3092 67.8015 12.1595 67.632 11.9721C67.4624 11.7848 67.3338 11.5639 67.2545 11.3238C67.1751 11.0836 67.1468 10.8295 67.1712 10.5777ZM69.997 10.5777C69.997 9.74805 69.6252 9.26288 68.9728 9.26288C68.3179 9.26288 67.9495 9.74806 67.9495 10.5777C67.9495 11.414 68.3179 11.8955 68.9728 11.8955C69.6252 11.8955 69.997 11.4107 69.997 10.5777Z'
        fill='white'
      />
      <path
        d='M71.7828 8.66554H72.508V9.27357H72.5643C72.6599 9.05519 72.8209 8.87212 73.0252 8.74989C73.2294 8.62765 73.4665 8.57234 73.7036 8.59166C73.8893 8.57766 74.0758 8.60574 74.2493 8.67382C74.4228 8.7419 74.5787 8.84824 74.7056 8.98495C74.8325 9.12167 74.9271 9.28526 74.9824 9.46361C75.0377 9.64196 75.0522 9.83049 75.025 10.0152V12.493H74.2715V10.2049C74.2715 9.58984 74.005 9.28395 73.4478 9.28395C73.3216 9.27806 73.1957 9.29958 73.0787 9.34703C72.9616 9.39449 72.8562 9.46676 72.7696 9.55889C72.6831 9.65101 72.6174 9.76082 72.5771 9.88078C72.5368 10.0007 72.5228 10.128 72.5362 10.2539V12.493H71.7828V8.66554Z'
        fill='white'
      />
      <path
        d='M79.2822 7.71289V8.68325H80.1093V9.3195H79.2822V11.2876C79.2822 11.6885 79.4469 11.8641 79.822 11.8641C79.918 11.8638 80.0139 11.858 80.1093 11.8467V12.4759C79.974 12.5001 79.8369 12.513 79.6994 12.5145C78.8616 12.5145 78.5279 12.2189 78.5279 11.481V9.31947H77.9219V8.68321H78.5279V7.71289H79.2822Z'
        fill='white'
      />
      <path
        d='M81.1382 7.17139H81.885V9.28061H81.9446C82.0448 9.06019 82.2103 8.87609 82.4186 8.75339C82.6269 8.6307 82.8679 8.57538 83.1087 8.59497C83.2935 8.58489 83.4782 8.61582 83.6497 8.68556C83.8212 8.75529 83.9752 8.86211 84.1008 8.9984C84.2264 9.13469 84.3204 9.29708 84.3762 9.47399C84.4319 9.65091 84.4481 9.83798 84.4234 10.0219V12.493H83.6693V10.2082C83.6693 9.59686 83.3852 9.28724 82.8529 9.28724C82.7234 9.27659 82.5932 9.29444 82.4713 9.33954C82.3494 9.38463 82.2388 9.45589 82.1472 9.54831C82.0557 9.64074 81.9854 9.75211 81.9412 9.87462C81.8971 9.99714 81.8802 10.1278 81.8917 10.2576V12.493H81.1383L81.1382 7.17139Z'
        fill='white'
      />
      <path
        d='M88.8166 11.4598C88.7142 11.8096 88.4925 12.1122 88.1901 12.3146C87.8877 12.517 87.5239 12.6064 87.1624 12.5671C86.9109 12.5737 86.6609 12.5254 86.4299 12.4255C86.1989 12.3256 85.9923 12.1765 85.8245 11.9886C85.6567 11.8006 85.5318 11.5783 85.4583 11.337C85.3848 11.0958 85.3645 10.8414 85.3989 10.5915C85.3654 10.3409 85.386 10.0859 85.4593 9.84395C85.5327 9.60198 85.657 9.37863 85.8239 9.18902C85.9908 8.9994 86.1964 8.84796 86.4268 8.74493C86.6571 8.64191 86.9069 8.5897 87.1591 8.59186C88.2213 8.59186 88.8621 9.31946 88.8621 10.5214V10.7849H86.1664V10.8273C86.1546 10.9677 86.1722 11.1091 86.2181 11.2423C86.264 11.3756 86.3371 11.4977 86.4329 11.601C86.5286 11.7042 86.6448 11.7862 86.774 11.8418C86.9032 11.8974 87.0425 11.9253 87.1831 11.9238C87.3633 11.9454 87.5459 11.9129 87.7076 11.8303C87.8694 11.7477 88.0029 11.6187 88.0913 11.4597L88.8166 11.4598ZM86.1664 10.2263H88.0946C88.1041 10.0978 88.0867 9.96879 88.0435 9.84748C88.0003 9.72617 87.9323 9.61526 87.8438 9.52188C87.7553 9.42851 87.6483 9.35472 87.5296 9.30527C87.411 9.25582 87.2834 9.2318 87.1549 9.23476C87.0246 9.23311 86.8953 9.25764 86.7746 9.3069C86.6539 9.35615 86.5443 9.42913 86.4521 9.52154C86.36 9.61394 86.2873 9.7239 86.2382 9.84494C86.1891 9.96598 86.1647 10.0956 86.1664 10.2263Z'
        fill='white'
      />
    </svg>
  );
}
