'use client';

export default function Payment({ color = '#002A3A', width = '24', height = '24' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.75 4H2.25C1.42157 4 0.75 4.67157 0.75 5.5V19C0.75 19.8284 1.42157 20.5 2.25 20.5H21.75C22.5784 20.5 23.25 19.8284 23.25 19V5.5C23.25 4.67157 22.5784 4 21.75 4Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.75 8.5H23.25'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M5.25 13H13.5' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.25 16H10.5' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
