'use client';

export default function SocialYoutube({ height = '32', width = '32', color = '#F1EEE6' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.1967 8.23074L22.9648 8.22255L9.05794 8.22217C7.0957 8.22217 5.47378 9.74409 5.34207 11.6923L5.33387 11.9242L5.3335 20.0533C5.3335 22.0155 6.85542 23.6374 8.8036 23.7692L9.03549 23.7773L22.9424 23.7777C24.9046 23.7777 26.5266 22.2558 26.6583 20.3076L26.6665 20.0757L26.6668 11.9466C26.6668 9.98437 25.1449 8.36245 23.1967 8.23074ZM9.05794 9.5555L22.9199 9.55512L23.1293 9.56218C24.3562 9.64551 25.3335 10.687 25.3335 11.9466V20.0533L25.3268 20.2402C25.2435 21.4671 24.202 22.4444 22.9424 22.4444H9.05794L8.87102 22.4377C7.6441 22.3544 6.66683 21.3129 6.66683 20.0533V11.9466L6.6735 11.7597C6.75683 10.5328 7.79831 9.5555 9.05794 9.5555ZM13.1113 11.8044V19.5022C13.1113 20.0289 13.6934 20.3476 14.1371 20.0638L20.1549 16.2149C20.5649 15.9527 20.5649 15.3539 20.1549 15.0917L14.1371 11.2428C13.6934 10.959 13.1113 11.2776 13.1113 11.8044ZM14.4442 13.0213L18.5575 15.6533L14.4442 18.2839V13.0213Z'
        fill={color}
      />
    </svg>
  );
}
