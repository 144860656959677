'use client';

export default function PlanIcon({ width = 58, height = 258, color = '#0A89FF' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 58 58' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M36.25 45.675C36.25 48.1747 37.243 50.572 39.0105 52.3395C40.778 54.107 43.1753 55.1 45.675 55.1C48.1747 55.1 50.572 54.107 52.3395 52.3395C54.107 50.572 55.1 48.1747 55.1 45.675C55.1 43.1753 54.107 40.778 52.3395 39.0105C50.572 37.243 48.1747 36.25 45.675 36.25C43.1753 36.25 40.778 37.243 39.0105 39.0105C37.243 40.778 36.25 43.1753 36.25 45.675Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M43.9961 45.6756C43.9961 46.1199 44.1726 46.5461 44.4869 46.8604C44.8011 47.1746 45.2273 47.3511 45.6716 47.3511C46.116 47.3511 46.5422 47.1746 46.8564 46.8604C47.1707 46.5461 47.3472 46.1199 47.3472 45.6756C47.3472 45.2312 47.1707 44.805 46.8564 44.4908C46.5422 44.1765 46.116 44 45.6716 44C45.2273 44 44.8011 44.1765 44.4869 44.4908C44.1726 44.805 43.9961 45.2312 43.9961 45.6756Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.454 2.90039H9.34288C5.78372 2.90039 2.89844 6.0165 2.89844 9.86039V48.1404C2.89844 51.9844 5.78372 55.1004 9.34288 55.1004H25.454C29.0133 55.1004 31.8984 51.9844 31.8984 48.1404V9.86039C31.8984 6.0165 29.0133 2.90039 25.454 2.90039Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.89844 44.9502H31.8984'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
