export default function ArrowRight({ color = '#28393D', width = '22', height = '9' }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 22 9' fill='none'>
      <g id='Group 16'>
        <path
          id='Vector'
          d='M21 4.39087H1'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M17.6667 7.72428L21 4.39095L17.6667 1.05762'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
}
