'use client';

export default function ShippingIcon({ color = '#002A3A' }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.25 15.677C14.25 15.3031 14.5531 15 14.927 15H22.573C22.9469 15 23.25 15.3031 23.25 15.677V22.573C23.25 22.9469 22.9469 23.25 22.573 23.25H14.927C14.5531 23.25 14.25 22.9469 14.25 22.573V15.677Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.25 15H20.25V19.5H17.25V15Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.25 20.25V2.25C2.25 1.42157 2.92157 0.75 3.75 0.75H15.75C16.5784 0.75 17.25 1.42157 17.25 2.25V12'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.25 20.25H1.5C1.08579 20.25 0.75 20.5858 0.75 21V22.5C0.75 22.9142 1.08579 23.25 1.5 23.25H11.25'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5 9.375C13.7071 9.375 13.875 9.54289 13.875 9.75C13.875 9.95711 13.7071 10.125 13.5 10.125C13.2929 10.125 13.125 9.95711 13.125 9.75C13.125 9.54289 13.2929 9.375 13.5 9.375'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
