'use client';

export default function BenefitsChecklist({ color = '#28393D', height = '44', width = '44' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.25 2.74854C1.25 1.92011 1.92157 1.24854 2.75 1.24854H37.25C38.0784 1.24854 38.75 1.92011 38.75 2.74854V37.2485C38.75 38.077 38.0784 38.7485 37.25 38.7485H2.75C1.92157 38.7485 1.25 38.077 1.25 37.2485V2.74854Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 7.49854L12.5 17.4985L7.5 12.4985'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.75 13.7485H31.25'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 22.4985L12.5 32.4985L7.5 27.4985'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.75 28.7485H31.25'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
