'use client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckMark from '../icons/checkMark';

export default function TitleWithCheckMarkBullets({ data }) {
  return (
    <Box display='flex' flexDirection='column' data-testid='bulletWithCheckMark'>
      <Typography
        variant='bodyLMedium'
        color='colorTheme.contentPrimaryOnLight'
        sx={{ marginTop: { xs: 1, lg: -1 } }}
        marginBottom={3}
        data-testid='titleText'
      >
        {data.titleText}
      </Typography>
      {data.bullets.bullets.map((bullet, i) => (
        <Box display='flex' alignItems='center' gap='16px' margin='8px 0' key={bullet}>
          <Box>
            <CheckMark />
          </Box>
          <Typography color='colorTheme.contentPrimaryOnLight' variant='bodyS' data-testid={`bulletText${i}`}>
            {bullet}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
